// import * as React from 'react';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { headerData } from '../../data/headerCostum';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import SweetAlert2 from 'react-sweetalert2';
import { tokens } from '../../theme';
import { updateStatus } from '../global/updateStatusHelper';


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

const Daftar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const pin = makeid(6);
    const [swalProps, setSwalProps] = useState({});
    const [data, setData] = useState(null);

    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDialog = (event) => {
        event.preventDefault();
        setOpenDialog(true);
        const data = new FormData(event.currentTarget);
        setData(data);
    }
    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const sendData = () => {
        fetch(process.env.REACT_APP_API_URL + "api/pendaftar", {
            method: 'post',
            headers: headerData,
            body: JSON.stringify({
                nama: data.get('nama').toString().toUpperCase(),
                pin: pin,
                email: data.get('email'),
                no_telpon: data.get('no_telpon'),
                nama_ormas: data.get('nama_ormas'),
                type: 1,
                status: 0
            })
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.nama) {
                    setSwalProps({
                        show: true,
                        title: 'Sukses',
                        icon: 'success',
                        text: 'Data telah ditambahkan'
                    });
                    updateStatus({
                        permohonan_layanan: "User Terdaftar",
                        varifikasi: "Menunggu Menyelesaikan Penginputan Data",
                        id_user: res.id
                    })
                    navigate('/user/beranda', {
                        state: {
                            user: res
                        }
                    });
                } else {
                    setSwalProps({
                        show: true,
                        title: 'Gagal',
                        icon: 'error',
                        text: res.message
                    });
                }
            })
            .catch(err => {
                console.log(err)
            })
        setOpenDialog(false);
    }

    return (
        <Grid
            item
            md={12}
            sx={{
                alignContent: 'center',
                alignItems: 'center'
            }}
        >
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs"
                >
                    <CssBaseline />
                    <SweetAlert2 {...swalProps}
                        didClose={() => {
                            setSwalProps({
                            });
                        }}
                    />
                    <Dialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle align='center' sx={{ justifyItems: 'center' }} id="alert-dialog-title">
                            Konfirmasi
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Apakah anda yakin bahwa data yang dimasukkan telah sesuai ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{
                            justifyContent: 'space-between'
                        }}>
                            <Button variant='contained' onClick={handleCloseDialog}
                                sx={{
                                    background: '#B40000'
                                }}
                            >
                                Kembali</Button>
                            <Button variant='contained' onClick={sendData} autoFocus
                                sx={{
                                    background: '#4985F8'
                                }}
                            >
                                Yakin
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box component="form" onSubmit={handleOpenDialog}>
                            <TextField
                                margin="dense"
                                required
                                fullWidth
                                id="nama"
                                label="Nama"
                                name="nama"
                                size='small'
                            />
                            <TextField
                                margin="dense"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                size='small'
                            />
                            <TextField
                                margin="dense"
                                required
                                fullWidth
                                id="no_telpon"
                                type="number"
                                label="No Telpon"
                                name="no_telpon"
                                size='small'
                            />
                            <TextField
                                margin="dense"
                                required
                                fullWidth
                                id="nama_ormas"
                                label="Nama Ormas"
                                name="nama_ormas"
                                size='small'
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 1,
                                    mb: 2,
                                    bgcolor: colors.blueAccent[500],
                                    textTransform: 'none'
                                }}
                                size='small'
                            >
                                Daftar
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </Grid>
    );
}

export default Daftar;