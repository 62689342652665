import { headerData } from "../../data/headerCostum"

export const updateStatus = ({ permohonan_layanan, varifikasi, berkas_lengkap, proses_unit_kerja, id_user }) => {
    fetch(process.env.REACT_APP_API_URL + "api/traking_permohonan", {
        method: 'post',
        headers: headerData,
        body: JSON.stringify({
            permohonan_layanan: permohonan_layanan ? permohonan_layanan : null,
            varifikasi: varifikasi ? varifikasi : null,
            berkas_lengkap: berkas_lengkap ? berkas_lengkap : null,
            proses_unit_kerja: proses_unit_kerja ? proses_unit_kerja : null,
            id_user: id_user
        })
    })
    .then(res => {
        return res.json()
    })
    .then(res => {
        if (res.id_user) {
            console.log("status updated")
        } else {
            console.log("status gagal updated, hubungi admin")
        }
    })
    .catch(err => {
        console.log(err)
    })
}