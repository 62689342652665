import { Box, Button, Card, Dialog, DialogActions, DialogTitle, Divider, FormControl, Grid, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { tokens } from "../../theme";
import { Link, useNavigate } from "react-router-dom";
import MapDialog from "./map";
import { styled } from '@mui/material/styles';
import { ArrowBackOutlined, PersonPinCircleRounded, } from "@mui/icons-material";
import { headerData, headerFormData } from "../../data/headerCostum";
import SweetAlert2 from 'react-sweetalert2';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const center = {
    lat: -5.160543,
    lng: 119.436077,
}

const bidang_kegiatan_list = [
    { label: "Lingkungan Hidup" },
    { label: "Energi / Sumber Daya Alam" },
    { label: "Pendidikan" },
    { label: "Ekonomi" },
    { label: "Seni" },
    { label: "Budaya" },
    { label: "Hukum" },
    { label: "Hobi, Minat, Bakat" },
    { label: "Perlindungan HAM" },
    { label: "Kemanusiaan" },
    { label: "Kebudayaan dan/atau Adat Istiadat" },
    { label: "Agama" },
    { label: "Kepercayaan Kepada Tuhan Yang Maha Esa" },
    { label: "Penelitian dan Pengembangan" },
    { label: "Penguatan Kapasitas" },
]

const ciri_khusus_list = [
    { label: "Keagamaan" },
    { label: "Kewanitaan" },
    { label: "Kepemudaan" },
    { label: "Kesamaan Kegiatan" },
    { label: "Kesamaan Profesi" },
    { label: "Kesamaan Bidang" },
    { label: "Mitra K/L" }
]

const SKT_data_permohonan = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    const [dialogMapOpen, setDialogMapOpen] = useState(false);
    const [position, setPosition] = useState(center)
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState(null);
    const [dataInput, setDataInput] = useState({
        jenis_permohonan: "",
        nomor_surat: "",
        tanggal_surat: "",
        nama_ormas: "",
        nama_singkatan: "",
        tempat_pendirian: "",
        tanggal_pendirian: "",
        no_akta: "",
        tanggal_akta: "",
        jenis_akta: "",
        nama_notaris: "",
        no_npwp: "",
        nama_bank: "",
        nomor_rekening: "",
        bidang_kegiatan: "",
        tujuan_ormas: "",
        ciri_khusus: ""
    });

    const [nosurat, setNoSurat] = useState('');
    const [tanggal_surat, setTanggalSurat] = useState('');
    const [scan_surat, setScanSurat] = useState(null);
    const [scan_akta_notaris, setAktaNotaris] = useState(null);
    const [scan_npwp, setNPWP] = useState(null);
    const [nama_ormas, setNamaOrmas] = useState(user.nama_ormas);
    const [detail_alamat, setDetailAlamat] = useState('');

    const [swalProps, setSwalProps] = useState({});

    const getDataUser = () => {
        fetch(process.env.REACT_APP_API_URL + "api/sktdatapermohonan/" + user.id, {
            method: 'get',
            headers: headerData
        })
            .then(res => res.json())
            .then((data) => {
                if (data) {
                    setData(data);
                    setNoSurat(JSON.parse(data.no_surat)[0]);
                    setTanggalSurat(JSON.parse(data.tanggal_surat)[0]);
                    setDetailAlamat(JSON.parse(data.detail_alamat)[0]);
                }
            })
            .catch((err) => console.log(err))
    }

    const sendData = () => {
        let formData = new FormData();
        formData.append('no_surat', JSON.stringify([nosurat, 0]));
        formData.append('tanggal_surat', JSON.stringify([tanggal_surat, 0]));
        formData.append('nama_ormas', JSON.stringify([nama_ormas, 0]));
        formData.append('alamat_sekret', JSON.stringify([position, 0]));
        formData.append('detail_alamat', JSON.stringify([detail_alamat, 0]));
        formData.append('scan_surat', scan_surat ? scan_surat[0] : null);
        formData.append('id_user', user.id);

        fetch(process.env.REACT_APP_API_URL + "api/sktdatapermohonan", {
            method: 'post',
            headers: headerFormData,
            body: formData
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                if (res.code == 200) {
                    navigate('/user/lkokepengurusan');
                } else {
                    {
                        setSwalProps({
                            show: true,
                            title: 'error'
                        });
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (!data) {
            getDataUser()
        }
    }, [data, getDataUser])


    return (
        <Grid
            container
            direction={"column"}
            sx={{
                bgcolor: '#D1E5F4',
            }}
            alignSelf={'center'}
            alignContent={'center'}
            height={'auto'}
        >
            <SweetAlert2 {...swalProps}>
                <h1>
                    {'Terjadi Kesalahan Penginputan'}
                </h1>
            </SweetAlert2>
            <Dialog onClose={() => setDialogMapOpen(false)} open={dialogMapOpen} fullWidth>
                <DialogTitle>
                    <Typography
                        variant="h5"
                        fontWeight={700}
                        textTransform={'capitalize'}
                    >
                        {'Pilih Point Alamat'}
                    </Typography>
                </DialogTitle>
                <Box height={'60vh'} width={'100%'} sx={{ paddingX: 2 }}>
                    <MapDialog pos={position} execute={setPosition} />
                </Box>
                <DialogActions>
                    <Button
                        variant="contained"
                        sx={{
                            textTransform: 'none',
                        }}
                        onClick={() => setDialogMapOpen(false)}
                    >
                        {'Tutup'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item position={'fixed'}>
                <Button
                    LinkComponent={Link}
                    to='/user/pilihpermohonan'
                >
                    <Typography textTransform={'capitalize'} fontSize={14} sx={{
                        display: 'flex'
                    }}>
                        <ArrowBackOutlined sx={{
                            marginX: '10px'
                        }} /> {'Surat Keterangan Terdaftar Bagi Ormas '}
                    </Typography>
                </Button>
            </Grid>
            <Box height={'50px'} />
            <Grid item sx={{
                marginX: 2
            }}>
                <Typography
                    textTransform={'capitalize'}
                    fontSize={24}
                    fontWeight={400}
                    color={'#00658B'}
                >
                    Surat Keterangan Terdaftar
                </Typography>
                <Typography
                    textTransform={'capitalize'}
                    fontSize={24}
                    fontWeight={400}
                    color={'#00658B'}
                >
                    Bagi Ormas
                </Typography>
                <Divider sx={{
                    border: 1,
                    color: '#00658B'
                }} />
            </Grid>
            <Grid item sx={{
                marginTop: 2,
                paddingX: 2
            }}>
                <Card
                    sx={{
                        paddingX: 2,
                        borderRadius: 4
                    }}
                >
                    <Grid container direction={'column'} alignContent={'center'}>
                        <Grid
                            item
                            container
                            direction={'row'}
                            // paddingX={2}
                            marginY={1}
                            sx={{
                                backgroundColor: '#C4E7FF',
                                borderRadius: 8,
                            }}
                        >
                            <Grid item container direction={'row'} justifyContent={'space-between'}>
                                <Grid item>
                                    <Button
                                        LinkComponent={Link}
                                        to={'/user/lkodatapermohonan'}
                                        variant="contained" size="small" sx={{
                                            background: '#00658B',
                                            ":hover": {
                                                background: '#C4E7FF',
                                            },
                                            borderRadius: 8,
                                            width: '100%'
                                        }}>
                                        1
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        LinkComponent={Link}
                                        to={'/user/lkokepengurusan'}
                                        variant="text" size="small" sx={{
                                            borderRadius: 8,
                                            width: '100%'
                                        }}>
                                        2
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        LinkComponent={Link}
                                        to={'/user/lkokepengurusan'}
                                        variant="text" size="small" sx={{
                                            borderRadius: 8,
                                            width: '100%'
                                        }}>
                                        3
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        LinkComponent={Link}
                                        to={'/user/lkoadministrasi'}
                                        variant="text" size="small" sx={{
                                            borderRadius: 8,
                                            width: '100%'
                                        }}>
                                        4
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={'column'}
                        >
                            <Grid item sx={{
                                marginY: 2
                            }}>
                                <Typography
                                    textTransform={'capitalize'}
                                    fontWeight={500}
                                    fontSize={'14px'}
                                    lineHeight={'20px'}
                                    color={'#00658B'}
                                >
                                    {'Data Permohonan'}
                                </Typography>
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Jenis Permohonan'}
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        size="small"
                                        labelId="jenis_permohonan_label"
                                        id="jenis_permohonan"
                                        value={dataInput.jenis_permohonan}
                                        onChange={(ev) => setDataInput({ ...dataInput, jenis_permohonan: ev.target.value })}
                                    >
                                        <MenuItem value={"pendaftaran"}>{"Pendaftaran SKT Ormas"}</MenuItem>
                                        <MenuItem value={"perubahan"}>{"Perubahan SKT Ormas"}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'No. Surat Permohonan'}
                                </Typography>
                                <TextField
                                    placeholder="Nomor Surat Permohonan"
                                    size="small"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={dataInput.nomor_surat}
                                    onChange={(ev) => setDataInput({ ...dataInput, nomor_surat: ev.target.value })}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Tanggal Surat Permohonan'}
                                </Typography>
                                <TextField
                                    placeholder="Tanggal Surat"
                                    size="small"
                                    type="date"
                                    fullWidth
                                    label=""
                                    variant="outlined"
                                    value={dataInput.tanggal_surat}
                                    onChange={(ev) => setDataInput({ ...dataInput, tanggal_surat: ev.target.value })}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Scan Surat Permohonan'}
                                </Typography>
                                <TextField
                                    placeholder="Nama File"
                                    size="small"
                                    type="text"
                                    label=""
                                    variant="outlined"
                                    value={scan_surat ? scan_surat[0].name : ''}
                                />
                                <Button
                                    sx={{
                                        backgroundColor: '#00658B',
                                        ':hover': {
                                            backgroundColor: '#00658B',
                                        },
                                        textTransform: "capitalize",
                                    }}
                                    size="medium" component="label" variant="contained">
                                    Pilih File
                                    <VisuallyHiddenInput type="file" name="image" onChange={
                                        (ev) => {
                                            if (ev.target.files[0]) {
                                                setScanSurat(ev.target.files)
                                            } else {
                                                setScanSurat(null)
                                            }
                                        }
                                    } />
                                </Button>
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Nama Ormas'}
                                </Typography>
                                <TextField
                                    placeholder="Masukkan Nama Ormas"
                                    size="small"
                                    type="text"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={dataInput.nama_ormas}
                                    onChange={(ev) => setDataInput({ ...dataInput, nama_ormas: ev.target.value })}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Nama Singkatan Ormas'}
                                </Typography>
                                <TextField
                                    placeholder="Masukkan Nama Singkatan Ormas"
                                    size="small"
                                    type="text"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={dataInput.nama_singkatan}
                                    onChange={(ev) => setDataInput({ ...dataInput, nama_singkatan: ev.target.value })}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Tempat Pendirian'}
                                </Typography>
                                <TextField
                                    placeholder="Masukkan Tempat Pendirian"
                                    size="small"
                                    type="text"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={dataInput.tempat_pendirian}
                                    onChange={(ev) => setDataInput({ ...dataInput, tempat_pendirian: ev.target.value })}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'No. Akta Notaris'}
                                </Typography>
                                <TextField
                                    placeholder="Masukkan No. Akta Notaris"
                                    size="small"
                                    type="text"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={dataInput.no_akta}
                                    onChange={(ev) => setDataInput({ ...dataInput, no_akta: ev.target.value })}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Tanggal Akta Notaris'}
                                </Typography>
                                <TextField
                                    placeholder="Masukkan Tanggal Akta Notaris"
                                    size="small"
                                    type="date"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={dataInput.tanggal_akta}
                                    onChange={(ev) => setDataInput({ ...dataInput, tanggal_akta: ev.target.value })}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Jenis Akta Notaris'}
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        size="small"
                                        labelId="jenis_akta_notaris"
                                        id="jenis_akta"
                                        value={dataInput.jenis_akta}
                                        onChange={(ev) => setDataInput({ ...dataInput, jenis_akta: ev.target.value })}
                                    >
                                        <MenuItem value={"pendirian"}>{"Akta Pendirian"}</MenuItem>
                                        <MenuItem value={"perubahan"}>{"Akta Perubahan"}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Nama Notaris'}
                                </Typography>
                                <TextField
                                    placeholder="Masukkan Nama Notaris"
                                    size="small"
                                    type="text"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={dataInput.nama_notaris}
                                    onChange={(ev) => setDataInput({ ...dataInput, nama_notaris: ev.target.value })}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'File Scan Akta Notaris'}
                                </Typography>
                                <TextField
                                    placeholder="Nama File"
                                    size="small"
                                    type="text"
                                    label=""
                                    variant="outlined"
                                    value={scan_akta_notaris ? scan_akta_notaris[0].name : ''}
                                />
                                <Button
                                    sx={{
                                        backgroundColor: '#00658B',
                                        ':hover': {
                                            backgroundColor: '#00658B',
                                        },
                                        textTransform: "capitalize",
                                    }}
                                    size="medium" component="label" variant="contained">
                                    Pilih File
                                    <VisuallyHiddenInput type="file" name="image" onChange={
                                        (ev) => {
                                            if (ev.target.files[0]) {
                                                setAktaNotaris(ev.target.files)
                                            } else {
                                                setAktaNotaris(null)
                                            }
                                        }
                                    } />
                                </Button>
                            </Grid>
                            <Divider sx={{
                                color: "GrayText",
                                marginY: 1
                            }} />
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Nomor NPWP'}
                                </Typography>
                                <TextField
                                    placeholder="Masukkan Nomor NPWP"
                                    size="small"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={dataInput.no_npwp}
                                    onChange={(ev) => setDataInput({ ...dataInput, no_npwp: ev.target.value })}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'File Scan NPWP'}
                                </Typography>
                                <TextField
                                    placeholder="Nama File"
                                    size="small"
                                    type="text"
                                    label=""
                                    variant="outlined"
                                    value={scan_npwp ? scan_npwp[0].name : ''}
                                />
                                <Button
                                    sx={{
                                        backgroundColor: '#00658B',
                                        ':hover': {
                                            backgroundColor: '#00658B',
                                        },
                                        textTransform: "capitalize",
                                    }}
                                    size="medium" component="label" variant="contained">
                                    Pilih File
                                    <VisuallyHiddenInput type="file" name="image" onChange={
                                        (ev) => {
                                            if (ev.target.files[0]) {
                                                setNPWP(ev.target.files)
                                            } else {
                                                setNPWP(null)
                                            }
                                        }
                                    } />
                                </Button>
                            </Grid>
                            <Divider sx={{
                                color: "GrayText",
                                marginY: 1
                            }} />
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Nama Bank'}
                                </Typography>
                                <TextField
                                    placeholder="Masukkan Nama Bank"
                                    size="small"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={dataInput.nama_bank}
                                    onChange={(ev) => setDataInput({ ...dataInput, nama_bank: ev.target.value })}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Nomor Rekening'}
                                </Typography>
                                <TextField
                                    placeholder="Masukkan Nomor Rekening"
                                    size="small"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={dataInput.nomor_rekening}
                                    onChange={(ev) => setDataInput({ ...dataInput, nomor_rekening: ev.target.value })}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Bidang Kegiatan'}
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        size="small"
                                        labelId="jenis_akta_notaris"
                                        id="jenis_akta"
                                        value={dataInput.bidang_kegiatan}
                                        onChange={(ev) => setDataInput({ ...dataInput, bidang_kegiatan: ev.target.value })}
                                    >
                                        {
                                            bidang_kegiatan_list.map((item) => {
                                                return (
                                                    <MenuItem value={item.label}>
                                                        {item.label}
                                                    </MenuItem>)
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Tujuan Ormas'}
                                </Typography>
                                <TextField
                                    placeholder="Jelaskan Tujuan Ormas"
                                    multiline
                                    rows={3}
                                    size="small"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={dataInput.tujuan_ormas}
                                    onChange={(ev) => setDataInput({ ...dataInput, tujuan_ormas: ev.target.value })}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Ciri Khusus'}
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        size="small"
                                        labelId="jenis_ciri_khusus"
                                        id="jenis_ciri"
                                        value={dataInput.ciri_khusus}
                                        onChange={(ev) => setDataInput({ ...dataInput, ciri_khusus: ev.target.value })}
                                    >
                                        {
                                            ciri_khusus_list.map((item) => {
                                                return (
                                                    <MenuItem value={item.label}>
                                                        {item.label}
                                                    </MenuItem>)
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item container direction={'row'} marginY={1} justifyContent={'end'}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => sendData()}
                                    sx={{
                                        borderRadius: 3,
                                        background: '#00658B',
                                        ":hover": {
                                            background: '#C4E7FF',
                                        },
                                        paddingX: 5,
                                        paddingY: 1
                                    }}
                                >
                                    <Typography fontSize={'13px'} textTransform={'capitalize'}>{'Selanjutnya >'}</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box height={10} />
                </Card>
                <Box height={25} />
            </Grid>
        </Grid >
    );
}

export default SKT_data_permohonan;