// import * as React from 'react';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { createTheme, useTheme } from '@mui/material/styles';
import { Button, Card, Divider, Grid, TextField, Typography } from '@mui/material';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { tokens } from '../../theme';
import Wave from '../../assets/image/wave1.png'
import Chat from '../../assets/image/konsultasi.png'
import { headerData } from '../../data/headerCostum';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import { WhatsApp } from '@mui/icons-material';

export default function Konsultasi() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataTracking, setDataTracking] = useState(null);
    const [dataUser, setDataUser] = useState({
        nama: '',
        nama_ormas: '',
        no_telepon: '',
        email: ''
    });

    const user = JSON.parse(localStorage.getItem('user'));
    const textWA = "Halo, saya ingin konsultasi";

    const getDataPermohonan = () => {
        fetch(process.env.REACT_APP_API_URL + "api/traking_permohonan/" + user.id, {
            method: 'get',
            headers: headerData,
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.id_user) {
                    setDataTracking(res);
                    // console.log(res)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (!dataTracking) {
            getDataPermohonan();
        }
    }, [dataTracking, getDataPermohonan])

    return (
        <Grid
            container
            direction={"column"}
            alignSelf={'center'}
            alignContent={'center'}
            height={'100vh'}
            maxWidth={'xs'}
        >
            <Box width={'100vw'} height={'100vh'} zIndex={-1} position={'fixed'} top={0} sx={{
                bgcolor: '#D1E5F4',
            }}>
                <img src={Wave} alt='wave' width={'100%'} />
            </Box>
            <Grid container item direction={'column'}
                sx={{
                    padding: 2,
                    marginTop: 6,
                }}>
                <Grid container item direction={'column'} alignContent={'center'}>
                    <Grid item>
                        <Card sx={{
                            paddingX: 3,
                            paddingY: 2,
                            marginTop: 3,
                            borderRadius: 4,
                            backgroundColor: "#94F990"
                        }}>
                            <Grid container>
                                <Grid item>
                                    <Typography variant='h2'>
                                        Konsultasi Online
                                    </Typography>
                                    <Typography variant='body1' marginBottom={1}>Aktif 09:00 - 17:00</Typography>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        endIcon={<WhatsApp />}
                                        color="success"
                                        sx={{
                                            textTransform: 'capitalize',
                                            borderRadius: 2,
                                            // backgroundColor: '#00658B',
                                            color: 'ButtonShadow'
                                        }}
                                        target="_black"
                                        href={"https://wa.me/+62" + (user ? parseInt(user.no_telpon) : '') + "?text=Hi " + (user ? user.nama : '') + ', ' + textWA + (user ? user.pin : '')}
                                    >
                                        {'Chat  Sekarang'}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <img src={Chat} alt='wave' width={'100%'} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid marginY={2}>
                        <Divider
                            sx={{
                                borderColor: 'CaptionText',
                                marginBottom: 2
                            }}>{"Atau"}</Divider>
                    </Grid>
                    <Grid>
                        <Card
                            sx={{
                                paddingX: 2,
                                borderRadius: 4
                            }}
                        >
                            <Grid container direction={'column'} alignContent={'center'}>
                                <Grid
                                    item
                                    container
                                    direction={'column'}
                                >
                                    <Grid item sx={{
                                        marginY: 2
                                    }}>
                                        <Typography
                                            textTransform={'capitalize'}
                                            fontWeight={700}
                                            fontSize={'20px'}
                                            lineHeight={'20px'}
                                            color={'#00658B'}
                                        >
                                            {'Daftar Konsultasi Langsung'}
                                        </Typography>
                                    </Grid>
                                    <Grid item marginY={1}>
                                        <Typography
                                            textTransform={'none'}
                                            fontSize={'12px'}
                                            fontStyle={'normal'}
                                        >
                                            {'Nama'}
                                        </Typography>
                                        <TextField
                                            placeholder="Masukkan Nama"
                                            size="small"
                                            label=""
                                            variant="outlined"
                                            fullWidth
                                            value={dataUser.nama}
                                            onChange={(ev) => setDataUser({ ...dataUser, nama: ev.target.value })}
                                        />
                                    </Grid>
                                    <Grid item marginY={1}>
                                        <Typography
                                            textTransform={'none'}
                                            fontSize={'12px'}
                                            fontStyle={'normal'}
                                        >
                                            {'Nama Ormas'}
                                        </Typography>
                                        <TextField
                                            placeholder="Masukkan Nama Ormas"
                                            size="small"
                                            type="text"
                                            label=""
                                            variant="outlined"
                                            fullWidth
                                            value={dataUser.nama_ormas}
                                            onChange={(ev) => setDataUser({ ...dataUser, nama_ormas: ev.target.value })}
                                        />
                                    </Grid>
                                    <Grid item marginY={1}>
                                        <Typography
                                            textTransform={'none'}
                                            fontSize={'12px'}
                                            fontStyle={'normal'}
                                        >
                                            {'No. Telepon'}
                                        </Typography>
                                        <TextField
                                            placeholder="Masukkan No. Telepon"
                                            size="small"
                                            type="number"
                                            label=""
                                            variant="outlined"
                                            fullWidth
                                            value={dataUser.no_telepon}
                                            onChange={(ev) => setDataUser({ ...dataUser, no_telepon: ev.target.value })}
                                        />
                                    </Grid>
                                    <Grid item marginY={1}>
                                        <Typography
                                            textTransform={'none'}
                                            fontSize={'12px'}
                                            fontStyle={'normal'}
                                        >
                                            {'E-mail'}
                                        </Typography>
                                        <TextField
                                            placeholder="Masukkan E-mail"
                                            size="small"
                                            type="text"
                                            label=""
                                            variant="outlined"
                                            fullWidth
                                            value={dataUser.email}
                                            onChange={(ev) => setDataUser({ ...dataUser, email: ev.target.value })}
                                        />
                                    </Grid>
                                    <Grid item container direction={'row'} marginY={1} justifyContent={'end'}>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            fullWidth
                                            target="_black"
                                            href={"mailto:" + (user ? user.email : '') + "?subject=Konsultasi%20Offline&body=" + (
                                                "Halo, saya ingin daftar konsultasi langsung%0D%0ANama: "+dataUser.nama+"%0D%0ANama Ormas: "+dataUser.nama_ormas+"%0D%0ANo. Telepon: "+dataUser.no_telepon+"%0D%0Aemail: "+dataUser.email
                                            ) + (user ? user.pin : '')}
                                            sx={{
                                                borderRadius: 3,
                                                background: '#00658B',
                                                ":hover": {
                                                    background: '#C4E7FF',
                                                },
                                                paddingX: 5,
                                                paddingY: 1
                                            }}
                                        >
                                            <Typography fontSize={'13px'} textTransform={'capitalize'}>{'Daftar Konsultasi Offline'}</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box height={10} />
                        </Card>
                    </Grid>
                    <Box height={'50px'} />
                </Grid>
            </Grid>
        </Grid>
    );
}