// import * as React from 'react';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { createTheme, useTheme } from '@mui/material/styles';
import { Button, Card, Divider, Grid, Typography } from '@mui/material';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { tokens } from '../../theme';
import Wave from '../../assets/image/wave1.png'
import { headerData } from '../../data/headerCostum';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';

export default function Beranda() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataTracking, setDataTracking] = useState(null);
    const user = JSON.parse(localStorage.getItem('user'));

    const getDataPermohonan = () => {
        fetch(process.env.REACT_APP_API_URL + "api/traking_permohonan/" + user.id, {
            method: 'get',
            headers: headerData,
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.id_user) {
                    setDataTracking(res);
                    // console.log(res)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (!dataTracking) {
            getDataPermohonan();
        }
    }, [dataTracking, getDataPermohonan])

    return (
        <Grid
            container
            direction={"column"}
            alignSelf={'center'}
            alignContent={'center'}
            height={'100vh'}
            maxWidth={'xs'}
        >
            <Box width={'100vw'} height={'100vh'} zIndex={-1} position={'fixed'} top={0} sx={{
                bgcolor: '#D1E5F4',
            }}>
                <img src={Wave} alt='wave' width={'100%'} />
            </Box>
            <Grid container item direction={'column'}
                sx={{
                    padding: 2,
                    marginTop: 6,
                }}>
                <Grid item container direction={'row'} justifyContent={'center'}>
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                        Halo
                        <Typography variant='h3' textTransform={'capitalize'}>
                            {user.nama}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item direction={'column'} alignContent={'center'}>
                    <Grid item>
                        <Card sx={{
                            paddingX: 3,
                            paddingY: 2,
                            marginTop: 3,
                            borderRadius: 4
                        }}>
                            <Typography variant='body1'>Nama Organisasi</Typography>
                            <Typography variant='h2' marginBottom={3}>
                                {user.nama_ormas}
                            </Typography>
                            <Button
                                fullWidth
                                variant='contained'
                                sx={{
                                    textTransform: 'none',
                                    bgcolor: colors.greenAccent[400],
                                    ":hover": {
                                        bgcolor: colors.blueAccent[500],
                                    },
                                    alignItems: 'flex-start',
                                    borderRadius: 2
                                }}
                                LinkComponent={Link}
                                to='/user/pilihpermohonan'
                            >
                                Buat Permohonan
                            </Button>
                        </Card>
                    </Grid>
                    <Card sx={{
                        paddingX: 3,
                        paddingY: 2,
                        marginTop: 3,
                        borderRadius: 4
                    }}>
                        <Typography variant='body1' marginBottom={0.5}>
                            Tracking Permohonan
                        </Typography>
                        <Divider sx={{
                            borderColor: 'CaptionText',
                            marginBottom: 2
                        }} />
                        <Box minWidth={100}>
                            {
                                dataTracking
                                    ? <Timeline
                                        sx={{
                                            [`& .${timelineItemClasses.root}:before`]: {
                                                flex: 0,
                                                padding: 0,
                                            },
                                        }}>
                                        {
                                            dataTracking.permohonan_layanan
                                                ? <TimelineItem key={dataTracking.id}>
                                                    <TimelineSeparator>
                                                        <TimelineDot color={dataTracking.permohonan_layanan ? 'success' : 'grey'} />
                                                        <TimelineConnector />
                                                    </TimelineSeparator>
                                                    <TimelineContent sx={{ py: '3px', px: 1 }}>
                                                        <Typography color={!dataTracking.permohonan_layanan ? 'GrayText' : 'InfoText'} variant="body1" fontWeight={600} component="span">
                                                            {"Permohonan Layanan"}
                                                        </Typography>
                                                        <Typography variant="body1">{dataTracking.permohonan_layanan ? dataTracking.permohonan_layanan : ""}</Typography>
                                                    </TimelineContent>
                                                </TimelineItem>
                                                : <></>
                                        }
                                        {
                                            dataTracking.varifikasi
                                                ? <TimelineItem key={dataTracking.id + 1}>
                                                    <TimelineSeparator>
                                                        <TimelineDot color={dataTracking.varifikasi ? (dataTracking.berkas_lengkap ? "success" : "warning") : 'grey'} />
                                                        <TimelineConnector />
                                                    </TimelineSeparator>
                                                    <TimelineContent sx={{ py: '3px', px: 1 }}>
                                                        <Typography color={!dataTracking.varifikasi ? 'GrayText' : 'InfoText'} variant="body1" fontWeight={600} component="span">
                                                            {"Verifikasi"}
                                                        </Typography>
                                                        <Typography variant="body1">{dataTracking.varifikasi ? dataTracking.varifikasi : ""}</Typography>
                                                    </TimelineContent>
                                                </TimelineItem>
                                                : <></>
                                        }
                                        {
                                            dataTracking.berkas_lengkap
                                                ? <TimelineItem key={dataTracking.id + 2}>
                                                    <TimelineSeparator>
                                                    <TimelineDot color={dataTracking.berkas_lengkap ? (dataTracking.proses_unit_kerja ? "success" : "warning") : 'grey'} />
                                                        <TimelineConnector />
                                                    </TimelineSeparator>
                                                    <TimelineContent sx={{ py: '3px', px: 1 }}>
                                                        <Typography color={!dataTracking.berkas_lengkap ? 'GrayText' : 'InfoText'} variant="body1" fontWeight={600} component="span">
                                                            {"Berkas Lengkap"}
                                                        </Typography>
                                                        <Typography variant="body1">{dataTracking.berkas_lengkap ? dataTracking.berkas_lengkap : "Menuggu perubahan status"}</Typography>
                                                    </TimelineContent>
                                                </TimelineItem>
                                                : <></>
                                        }
                                        {
                                            dataTracking.proses_unit_kerja
                                                ? <TimelineItem key={dataTracking.id + 3}>
                                                    <TimelineSeparator>
                                                        <TimelineDot color={dataTracking.proses_unit_kerja ? 'success' : 'grey'} />
                                                    </TimelineSeparator>
                                                    <TimelineContent sx={{ py: '3px', px: 1 }}>
                                                        <Typography color={!dataTracking.proses_unit_kerja ? 'GrayText' : 'InfoText'} variant="body1" fontWeight={600} component="span">
                                                            {"Proses Unit Kerja"}
                                                        </Typography>
                                                        <Typography variant="body1">{dataTracking.proses_unit_kerja ? dataTracking.proses_unit_kerja : ""}</Typography>
                                                    </TimelineContent>
                                                </TimelineItem>
                                                : <></>
                                        }
                                    </Timeline>
                                    : <></>
                            }
                        </Box>
                        <Button
                            fullWidth
                            variant='contained'
                            LinkComponent={Link}
                                to='/user/tracking'
                            sx={{
                                textTransform: 'none',
                                bgcolor: colors.blueAccent[500],
                                ":hover": {
                                    bgcolor: colors.blueAccent[500],
                                },
                                alignItems: 'flex-start',
                                borderRadius: 2
                            }}
                        >
                            Lihat Selengkapnya
                        </Button>
                    </Card>
                    <Card sx={{
                        paddingX: 3,
                        paddingY: 2,
                        marginTop: 3,
                        borderRadius: 4
                    }}>
                        <Typography variant='body1' marginBottom={0.5}>
                            Syarat Permohonan
                        </Typography>
                        <Divider sx={{
                            borderColor: 'CaptionText',
                            marginBottom: 2
                        }} />
                        <Typography variant='body2' marginBottom={1}>
                            Pastikan kamu sudah menyiapkan dokumen persyaratan yang tepat.
                        </Typography>
                        <Button
                            fullWidth
                            variant='contained'
                            sx={{
                                textTransform: 'none',
                                bgcolor: colors.blueAccent[500],
                                ":hover": {
                                    bgcolor: colors.blueAccent[500],
                                },
                                alignItems: 'flex-start',
                                borderRadius: 2
                            }}
                        >
                            Unduh Syarat Permohonan
                        </Button>
                    </Card>
                    <Card sx={{
                        paddingX: 3,
                        paddingY: 2,
                        marginTop: 3,
                        borderRadius: 4
                    }}>
                        <Typography variant='body1' marginBottom={0.5}>
                            Template File Persyaratan
                        </Typography>
                        <Divider sx={{
                            borderColor: 'CaptionText',
                            marginBottom: 2
                        }} />
                        <Button
                            fullWidth
                            variant='contained'
                            sx={{
                                textTransform: 'none',
                                bgcolor: colors.blueAccent[500],
                                ":hover": {
                                    bgcolor: colors.blueAccent[500],
                                },
                                alignItems: 'flex-start',
                                borderRadius: 2
                            }}
                        >
                            {"Formulir Isian Data Ormas Ditandatangani Ketua & Sekretaris"}
                        </Button>
                    </Card>
                    <Box height={'50px'} />
                </Grid>
            </Grid>
        </Grid>
    );
}