import { Box, Button, Card, Chip, Divider, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { ArrowBackOutlined, UploadFileRounded, VisibilityRounded, } from "@mui/icons-material";
import { headerData, headerFormData } from "../../data/headerCostum";
import SweetAlert2 from 'react-sweetalert2';
import { statusConvert } from "../global/statusconversion";


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const column = [
    {
        id: 'pengesahan_akte',
        label: 'Pengesahan akte pendirian dari Kementerian Hukum dan HAM Republik Indonesia atau Legalitas Kelembagaan atau dasar Pembentukan Organisasi'
    },
    {
        id: 'akte_pendirian',
        label: 'Akte Pendirian atau status orkemas yang disahkan Notaris'
    },
    {
        id: 'adart',
        label: 'Anggaran Dasar dan Anggaran Rumah Tangga yang disahkan notaris'
    },
    {
        id: 'tujuan_program',
        label: 'Tujuan dan Program Kerja organisasi yang ditanda tangani oleh Ketua dan Sekretaris'
    },
    {
        id: 'sk_spo',
        label: 'Surat Keputusan tentang Susunan Pengurus Orkemas secara lengkap yang sah sesuai anggaran dasar dan anggran rumah tangga '
    },
    {
        id: 'file_biodata_ketua',
        label: 'File Scan Biodata Ketua '
    },
    {
        id: 'foto_ketua',
        label: 'File Scan Foto Ketua Berwarna 4x6 (3 bulan Terakhir)'
    },
    {
        id: 'file_ktp_ketua',
        label: 'File Scan KTP Ketua'
    },
    {
        id: 'file_biodata_sekretaris',
        label: 'File Scan Biodata Sekretaris atau Sebutan Lain'
    },
    {
        id: 'foto_sekretaris',
        label: 'File Scan Foto Sekretaris Berwarna 4x6 (3 bulan Terakhir)'
    },
    {
        id: 'file_ktp_sekretaris',
        label: 'File Scan KTP Sekretaris'
    },
    {
        id: 'file_biodata_bendahara',
        label: 'File Scan Biodata Bendahara atau Sebutan Lain'
    },
    {
        id: 'foto_bendahara',
        label: 'File Scan Foto Bendahara Berwarna 4x6 (3 bulan Terakhir)'
    },
    {
        id: 'file_ktp_bendahara',
        label: 'File Scan KTP Bendahara'
    },
    {
        id: 'sk_domisili',
        label: 'Surat keterangan domisili organisasi dari Lurah / Camat dimana Organisasi berdomisili'
    },
    {
        id: 'foto_kantor',
        label: 'Foto Kantor atau Sekretariat Orkemas, tampak depan yang memuat papan nama Organisasi'
    },
    {
        id: 'keabsahan_kantor',
        label: 'Kebsahan kantor atau sekretariat orkemas dilampiri bukti kepemilikan, atau surat perjanjian kontrak atau ijin pakai dari pemilik / pengelola'
    },
    {
        id: 'sp_tidak_konflik',
        label: 'Surat Pernyataan tidak terjadi Konflik kepengurusan, yang ditandatangani oleh Ketua dan Sekretaris, atau sebutan lainnya.'
    }
]


const LKO_administrasi = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    const [data, setData] = useState(null);

    const [swalProps, setSwalProps] = useState({});

    const getDataUser = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/lkoadministrasi/" + user.id, {
            method: 'get',
            headers: headerData
        })
            .then(res => res.json())
            .then((data) => {
                if (data) {
                    setData(data);
                }
            })
            .catch((err) => console.log(err))
    }, [user.id])

    const sendData = (location, file) => {
        let formData = new FormData();
        formData.append('file', file)
        formData.append('location', location);
        formData.append('status', 0);
        formData.append('id_user', user.id);

        fetch(process.env.REACT_APP_API_URL + "api/lkoadministrasi", {
            method: 'post',
            headers: headerFormData,
            body: formData
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                if (res.code === 200) {
                    getDataUser()
                    return
                } else {
                    setSwalProps({
                        show: true,
                        title: 'error'
                    });
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (!data) {
            getDataUser()
        }
    }, [data, getDataUser])


    return (
        <Grid
            container
            direction={"column"}
            sx={{
                bgcolor: '#D1E5F4',
            }}
            alignSelf={'center'}
            alignContent={'center'}
            height={'auto'}
        >
            <SweetAlert2 {...swalProps}>
                <h1>
                    {'Terjadi Kesalahan Penginputan'}
                </h1>
            </SweetAlert2>
            <Grid item position={'fixed'}>
                <Button
                    LinkComponent={Link}
                    to='/user/pilihpermohonan'
                >
                    <Typography textTransform={'capitalize'} fontSize={14} sx={{
                        display: 'flex'
                    }}>
                        <ArrowBackOutlined sx={{
                            marginX: '10px'
                        }} /> {'Laporan Keberadaan Ormas'}
                    </Typography>
                </Button>
            </Grid>
            <Box height={'50px'} />
            <Grid item sx={{
                marginX: 2
            }}>
                <Typography
                    textTransform={'capitalize'}
                    fontSize={24}
                    fontWeight={400}
                    color={'#00658B'}
                >
                    Laporan
                </Typography>
                <Typography
                    textTransform={'capitalize'}
                    fontSize={24}
                    fontWeight={400}
                    color={'#00658B'}
                >
                    Keberadaan Ormas
                </Typography>
                <Divider sx={{
                    border: 1,
                    color: '#00658B'
                }} />
            </Grid>
            <Grid item sx={{
                marginTop: 2,
                paddingX: 2
            }}>
                <Card
                    sx={{
                        paddingX: 2,
                        borderRadius: 4
                    }}
                >
                    <Grid container direction={'column'} alignContent={'center'}>
                        <Grid
                            item
                            container
                            direction={'row'}
                            // paddingX={2}
                            marginY={1}
                            sx={{
                                backgroundColor: '#C4E7FF',
                                borderRadius: 8,
                            }}
                        >
                            <Grid item container direction={'row'} justifyContent={'space-between'}>
                                <Grid item>
                                    <Button
                                        LinkComponent={Link}
                                        to={'/user/lkodatapermohonan'}
                                        variant="text" size="small" sx={{
                                            borderRadius: 8,
                                            width: '100%'
                                        }}>
                                        1
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        LinkComponent={Link}
                                        to={'/user/lkokepengurusan'}
                                        variant="text" size="small" sx={{
                                            borderRadius: 8,
                                            width: '100%'
                                        }}>
                                        2
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        LinkComponent={Link}
                                        to={'/user/lkoadministrasi'}
                                        variant="contained" size="small" sx={{
                                            background: '#00658B',
                                            ":hover": {
                                                background: '#C4E7FF',
                                            },
                                            borderRadius: 8,
                                            width: '100%'
                                        }}>
                                        3
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={'column'}
                        >
                            <Grid item sx={{
                                marginY: 2
                            }}>
                                <Typography
                                    textTransform={'capitalize'}
                                    fontWeight={500}
                                    fontSize={'14px'}
                                    lineHeight={'20px'}
                                    color={'#00658B'}
                                >
                                    {'Data Permohonan'}
                                </Typography>
                            </Grid>
                            {
                                column.map(item => (
                                    <Grid key={item.id} item marginY={1}>
                                        <Typography
                                            textTransform={'none'}
                                            fontSize={'12px'}
                                            fontStyle={'normal'}
                                            maxWidth={'312px'}
                                        >
                                            {item.label}
                                        </Typography>
                                        {/* <TextField
                                            placeholder="Nama File"
                                            size="small"
                                            type="text"
                                            label=""
                                            variant="outlined"
                                            value={fileUploads[item.id] ? fileUploads[item.id] : ''}
                                        /> */}
                                        {
                                            data && data[item.id]
                                                ? <Button
                                                    component={Link}
                                                    to={process.env.REACT_APP_API_URL + 'api/' + JSON.parse(data[item.id])[0]}
                                                    target="_blank"
                                                    sx={{
                                                        marginY: 1,
                                                        // backgroundColor: '#00658B',
                                                        // ':hover': {
                                                        //     backgroundColor: '#00658B',
                                                        // },
                                                        textTransform: "capitalize",
                                                        textDecoration: 'underline'
                                                    }}
                                                    color="info"
                                                    size="medium" variant="outlined">
                                                    <VisibilityRounded /> {'Lihat File'}
                                                </Button>
                                                : <Chip sx={{
                                                    borderRadius: 2
                                                }} label={'Belum Upload File'} variant="outlined" />
                                        }
                                        {
                                            data && data[item.id]
                                                ? JSON.parse(data[item.id])[1] !== '2' ?
                                                    <><Button
                                                        sx={{
                                                            marginLeft: 1,
                                                            backgroundColor: '#00658B',
                                                            ':hover': {
                                                                backgroundColor: '#00658B',
                                                            },
                                                            textTransform: "capitalize",
                                                        }}
                                                        size="medium" component="label" variant="contained">
                                                        <UploadFileRounded sx={{
                                                            marginRight: 1
                                                        }} />{'Upload File'}
                                                        <VisuallyHiddenInput type="file" name="file" onChange={
                                                            (ev) => {
                                                                sendData(item.id, ev.target.files[0])
                                                            }
                                                        } />
                                                    </Button>
                                                        <Chip sx={{
                                                            borderRadius: 2
                                                        }} label={statusConvert(JSON.parse(data[item.id])[1]).text} color={statusConvert(JSON.parse(data[item.id])[1]).color} variant="filled" />
                                                    </>
                                                    : <Chip sx={{
                                                        borderRadius: 2,
                                                        marginLeft: 1
                                                    }} label={statusConvert(JSON.parse(data[item.id])[1]).text} color={statusConvert(JSON.parse(data[item.id])[1]).color} variant="filled" />
                                                : <Button
                                                    sx={{
                                                        marginLeft: 1,
                                                        backgroundColor: '#00658B',
                                                        ':hover': {
                                                            backgroundColor: '#00658B',
                                                        },
                                                        textTransform: "capitalize",
                                                    }}
                                                    size="medium" component="label" variant="contained">
                                                    <UploadFileRounded sx={{
                                                        marginRight: 1
                                                    }} />{'Upload File'}
                                                    <VisuallyHiddenInput type="file" name="file" onChange={
                                                        (ev) => {
                                                            sendData(item.id, ev.target.files[0])
                                                        }
                                                    } />
                                                </Button>
                                        }
                                    </Grid>
                                ))
                            }
                            <Grid item container direction={'row'} marginY={1} justifyContent={'space-between'}>
                                <Button
                                    LinkComponent={Link}
                                    to='/user/lkokepengurusan'
                                    variant="contained"
                                    size="small"
                                    sx={{
                                        borderRadius: 3,
                                        background: '#C4E7FF',
                                        color: '#00658B',
                                        border: 1,
                                        borderColor: '#00658B',
                                        ":hover": {
                                            background: '#C4E7FF',
                                        },
                                        paddingX: 3,
                                        paddingY: 1
                                    }}
                                >
                                    <Typography fontSize={'13px'} textTransform={'capitalize'}>{'< Sebelumnya'}</Typography>
                                </Button>
                                <Button
                                    LinkComponent={Link}
                                    to='/user/beranda'
                                    variant="contained"
                                    size="small"
                                    sx={{
                                        borderRadius: 3,
                                        background: '#C4E7FF',
                                        color: '#00658B',
                                        border: 1,
                                        borderColor: '#00658B',
                                        ":hover": {
                                            background: '#C4E7FF',
                                        },
                                        paddingX: 3,
                                        paddingY: 1
                                    }}
                                >
                                    <Typography fontSize={'13px'} textTransform={'capitalize'}>{'Beranda'}</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box height={10} />
                </Card>
                <Box height={25} />
            </Grid>
        </Grid >
    );
}

export default LKO_administrasi;