import { Avatar, Button, Card, Chip, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { headerData } from "../../../data/headerCostum";
import { EmailRounded, VisibilityOffOutlined, VisibilityOutlined, WhatsApp } from "@mui/icons-material";
import BasicTimeline from "./timeline";

const timeline = [
    {
        name: 'Permohonan Layanan',
        status: 1,
        desc: 'Menunggu Pembuatan Permohonan'
    },
    {
        name: 'Verifikasi',
        status: 0,
        desc: 'Menunggu Perubahan Status'
    },
    {
        name: 'Berkas Lengkap',
        status: 0,
        desc: 'Menunggu Perubahan Status'
    },
    {
        name: 'Proses Unit Kerja',
        status: 0,
        desc: 'Menunggu Perubahan Status'
    },
    {
        name: 'Selesai',
        status: 0,
        desc: 'Menunggu Perubahan Status',
        akhir: 1
    },
]

const DetailUser = () => {
    let { userId } = useParams();
    const location = useLocation();
    const [user, setUser] = useState(null);
    const [showpin, setShowPin] = useState(false);
    const textWA = "Berikut PIN anda untuk login: "


    const getDataUser = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/pendaftar/" + userId, {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                setUser(data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        if (!user) {
            getDataUser()
        }
    }, [user, setUser])

    return (
        <Grid container direction={'column'}>
            <Grid item marginBottom={2}>
                <Typography variant='h5'>
                    {location.pathname.split('/')[1] + ' / ' + location.pathname.split('/')[2]}
                </Typography>
                <Typography variant='h3' textTransform={'capitalize'}>
                    {user ? user.nama : ''}
                </Typography>
            </Grid>
            <Grid container direction={'row'} item>
                <Grid item xs={12} sm={12} lg={6} paddingX={1}>
                    <Card>
                        <Grid
                            container
                            direction={'column'}
                            sx={{
                                padding: 2
                            }}
                            gridTemplateColumns="repeat(12, 1fr)"
                            gridAutoRows="250px"
                            gap="20px"
                        >
                            <Grid item>
                                <Typography
                                    textTransform={'capitalize'}
                                    variant="h4"
                                    color={'#00658B'}
                                >
                                    {'Detail User'}
                                </Typography>
                            </Grid>
                            <Grid item
                                sx={{
                                    marginY: 1
                                }}
                            >
                                <Avatar
                                    src=""
                                    sx={{
                                        width: 100,
                                        height: 100
                                    }}
                                />
                            </Grid>
                            <Grid item container direction={'column'}>
                                <Grid item>
                                    <Typography textTransform={'capitalize'} variant="body2">
                                        {'nama user'}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" textTransform={'capitalize'}>
                                        {user ? user.nama : ''}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item container direction={'column'}>
                                <Grid item>
                                    <Typography textTransform={'capitalize'} variant="body2">
                                        {'email'}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" textTransform={'lowercase'}>
                                        {user ? user.email : ''}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item container direction={'column'}>
                                <Grid item>
                                    <Typography textTransform={'uppercase'} variant="body2">
                                        {'pin'}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        size='small'
                                        type={showpin ? 'text' : 'password'}
                                        variant="standard"
                                        value={user ? user.pin : ''}
                                        fullWidth
                                        sx={{
                                            background: '#D1E5F4',
                                            borderRadius: 2,
                                            width: '130px',
                                            paddingLeft: 1
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment>
                                                    <Button
                                                        onClick={() => { setShowPin(!showpin) }}
                                                        size='small' variant='text'
                                                    >
                                                        {
                                                            showpin
                                                                ? <VisibilityOffOutlined sx={{
                                                                    color: '#00658B'
                                                                }} />
                                                                : <VisibilityOutlined
                                                                    sx={{
                                                                        color: '#00658B'
                                                                    }}
                                                                />
                                                        }
                                                    </Button>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Button
                                        size="small"
                                        variant="contained"
                                        endIcon={<WhatsApp />}
                                        color="success"
                                        sx={{
                                            textTransform: 'capitalize',
                                            marginLeft: 1,
                                            borderRadius: 2,
                                            backgroundColor: '#00658B',
                                            color: 'ButtonShadow'
                                        }}
                                        target="_black"
                                        href={"https://wa.me/+62" + (user ? parseInt(user.no_telpon) : '') + "?text=Hi " + (user ? user.nama : '') + ', ' + textWA + (user ? user.pin : '')}
                                    >
                                        {'Kirim PIN'}
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        endIcon={<EmailRounded />}
                                        color="success"
                                        sx={{
                                            textTransform: 'capitalize',
                                            marginLeft: 1,
                                            borderRadius: 2,
                                            backgroundColor: '#00658B',
                                            color: 'ButtonShadow'
                                        }}
                                        target="_black"
                                        href={"mailto:" + (user ? user.email : '') + "?subject=PIN%20Kesbangpol&body=" + textWA + (user ? user.pin : '')}
                                    >
                                        {'Kirim PIN'}
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid item container direction={'column'}>
                                <Grid item>
                                    <Typography textTransform={'capitalize'} variant="body2">
                                        {'no telepon'}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" textTransform={'capitalize'}>
                                        {user ? user.no_telpon : ''}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item container direction={'column'}>
                                <Grid item>
                                    <Typography textTransform={'capitalize'} variant="body2">
                                        {'nama ormas'}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" textTransform={'capitalize'}>
                                        {user ? user.nama_ormas : ''}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} lg={6} paddingX={1}>
                    <Card>
                        <Grid
                            container
                            direction={'column'}
                            sx={{
                                padding: 2
                            }}
                            gridTemplateColumns="repeat(12, 1fr)"
                            gridAutoRows="250px"
                            gap="20px"
                        >
                            <Grid item>
                                <Typography
                                    textTransform={'capitalize'}
                                    variant="h4"
                                    color={'#00658B'}
                                >
                                    {'Data Permohonan'}
                                </Typography>
                            </Grid>

                            <Grid item container direction={'column'}>
                                <Grid item>
                                    <Typography textTransform={'capitalize'} variant="body2">
                                        {'nama ormas'}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" textTransform={'capitalize'}>
                                        {user ? user.nama_ormas : ''}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item container direction={'column'}>
                                <Grid item>
                                    <Typography textTransform={'capitalize'} variant="body2">
                                        {'nomor surat permohonan'}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" textTransform={'capitalize'}>
                                        {user ? user.nama_ormas : '-'}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item container direction={'column'}>
                                <Grid item>
                                    <Typography textTransform={'capitalize'} variant="body2">
                                        {'tracking'}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <BasicTimeline data={timeline} />
                                </Grid>
                            </Grid>



                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DetailUser;