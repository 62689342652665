import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import FolderCopyRoundedIcon from '@mui/icons-material/FolderCopyRounded';
import SmsRoundedIcon from '@mui/icons-material/SmsRounded';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material';

export default function BottomNavbar() {
    
    const [value, setValue] = React.useState(0);
    return (
        <Box sx={{ pb: 7 }}>
            <CssBaseline />
            <Box>
                <Outlet />
            </Box>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction LinkComponent={Link} to='beranda' label="Beranda" icon={<HomeRoundedIcon />} />
                    <BottomNavigationAction LinkComponent={Link} to='tracking' label="Tracking" icon={<FolderCopyRoundedIcon />} />
                    <BottomNavigationAction LinkComponent={Link} to='konsultasi' label="Konsultasi" icon={<SmsRoundedIcon />} />
                </BottomNavigation>
            </Paper>
        </Box>
    );
}