import React, { useCallback } from 'react';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import {
    Grid,
    Box,
    Typography,
    Button,
    InputAdornment,
    Chip,
} from '@mui/material';
import DownloadTable from './download';

import EditIcon from '@mui/icons-material/Edit';
import { headerData } from '../../../data/headerCostum';
import { Link, useLocation } from 'react-router-dom';



const columns = [
    {
        id: 'createdAt',
        label: 'Tanggal',
        minWidth: 150,
        format: ({ value }) => {
            const d = new Date(value)
            return (
                <>
                    {d.toLocaleString('ID')}
                </>
            )
        }
    },
    {
        id: 'no_surat',
        label: 'Nomor Surat',
        minWidth: 150,
        format: ({value}) =>{
            return(JSON.parse(value)[0])
        }
    },
    {
        id: 'nama_ormas',
        label: 'Nama Ormas',
        minWidth: 100,
        format: ({value}) =>{
            return(JSON.parse(value)[0])
        }
    },
    {
        id: 'status',
        label: 'Status',
        minWidth: 50,
        format: ({ value }) => {
            let string;
            switch (value) {
                case '0': string = { color: 'error', text: 'belum ada' }; break;
                case '1': string = { color: 'primary', text: 'permohonan layanan' }; break;
                case '2': string = { color: 'warning', text: 'verifikasi' }; break;
                case '3': string = { color: 'success', text: 'selesai' }; break;
                default: string = null;
            }
            return (
                <>
                    {
                        string
                            ? <Chip label={string.text} color={string.color} variant='outlined' />
                            : '...'
                    }
                </>
            )
        }
    }
];


export default function DaftarPermohonan() {
    const [rows, setRows] = useState([])
    const [showref, setShowRef] = useState({})
    let location = useLocation();
    //Page Number
    let rowNumber = 0;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    //End of Page Number

    const [copyList, setCopyList] = useState(rows);

    const getDataUser = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/lkodatapermohonan", {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                setRows(data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const searchText = (searched) => {
        setCopyList(rows.filter((item) =>
            item.nama.toUpperCase().includes(searched.toUpperCase())
        ))
    }

    //Page handle
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    //End of page Handle


    //End Of Filter handle

    useEffect(() => {
        if (rows.length === 0) {
            getDataUser()
        } else {
            setCopyList(rows)
        }
    },
        [getDataUser, rows]
    )

    return (
        <Grid container direction={'column'}>
            <Grid item marginBottom={2}>
                <Typography variant='h5'>
                    {location.pathname.split('/')[1] + ' / '+ location.pathname.split('/')[2]}
                </Typography>
                <Typography variant='h3' textTransform={'capitalize'}>
                    {'Laporan Keberadaan Ormas'}
                </Typography>
            </Grid>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Grid container xs={12} m={1} alignItems={'center'} justifyContent={'space-between'}>
                    <Grid conatiner item xs={6} md={5} lg={3}>
                        <TextField
                            id="outlined-textarea"
                            label="Cari"
                            placeholder="Nama"
                            sx={{
                                width: '100%'
                            }}
                            size='small'
                            onInput={(e) => searchText(e.target.value)}
                        />
                    </Grid>
                    <Grid container item xs={6} md={5} lg={3} sx={{
                        paddingRight: 0.5
                    }}
                        justifyContent={'end'}
                    >
                        <Box sx={{ marginX: 2 }}>
                            <DownloadTable columns={columns} rows={copyList} filename={'data_akun_user.csv'} />
                        </Box>
                    </Grid>
                </Grid>
                <TableContainer sx={{ maxHeight: '90vh', paddingX: 1 }}>
                    <Table>
                        <TableHead sx={{ backgroundColor: '#C4E7FF' }}>
                            <TableRow>
                                <TableCell
                                    key={'no'}
                                    align={'center'}
                                    style={{ minWidth: 5 }}
                                >
                                    {'No'}
                                </TableCell>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                                <TableCell
                                    key={'aksi'}
                                    align={'center'}
                                    style={{ minWidth: 20 }}
                                >
                                    {''}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {copyList.length > 0
                                ? copyList
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        rowNumber += 1;
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                <TableCell
                                                    align='center'
                                                    key={'no'}>
                                                    {rowNumber + page * rowsPerPage}
                                                </TableCell>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.format
                                                                ? column.format({ value: value, show: showref, execute: setShowRef })
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                                <TableCell
                                                    align='center'
                                                    key={'aksi'}
                                                >
                                                    <Grid container item>
                                                        <Button
                                                            LinkComponent={Link}
                                                            to={row.Pendaftar.id.toString()}
                                                            variant='text'
                                                            size='small'
                                                            sx={{
                                                                backgroundColor: '#C4E7FF',
                                                                borderRadius: 8,
                                                            }}>
                                                            <EditIcon sx={{
                                                                color: '#00658B'
                                                            }} />
                                                        </Button>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                : ''
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={copyList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Grid>
    );
}