import { Box, Button, Card, Container, Dialog, DialogTitle, Divider, Grid, Input, TextField, Typography, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { tokens } from "../../theme";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { ArrowBackOutlined } from "@mui/icons-material";
import { headerData } from "../../data/headerCostum";
import SweetAlert2 from 'react-sweetalert2';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});



const LKO_kepengurusan = () => {
    const navigate = useNavigate();
    const [swalProps, setSwalProps] = useState({});
    const user = JSON.parse(localStorage.getItem('user'));

    const [pendiri, setDataPendiri] = useState([]);
    const [pembina, setDataPembina] = useState([]);
    const [penasehat, setDataPenasehat] = useState([]);

    const [namaKetua, setNamaKetua] = useState('');
    const [nikKetua, setNikKetua] = useState('');
    const [masaKetuaMulai, setMasaKetuaMulai] = useState('');
    const [masaKetuaAkhir, setMasaKetuaAkhir] = useState('');

    const [namaSekretaris, setNamaSekretaris] = useState('');
    const [nikSekretaris, setNikSekretaris] = useState('');
    const [masaSekretarisMulai, setMasaSekretarisMulai] = useState('');
    const [masaSekretarisAkhir, setMasaSekretarisAkhir] = useState('');

    const [namaBendahara, setNamaBendahara] = useState('');
    const [nikBendahara, setNikBendahara] = useState('');
    const [masaBendaharaMulai, setMasaBendaharaMulai] = useState('');
    const [masaBendaharaAkhir, setMasaBendaharaAkhir] = useState('');


    const [data, setData] = useState(null);

    const sendData = () => {
        // console.log(pendiri)
        fetch(process.env.REACT_APP_API_URL + "api/lkokepengurusan", {
            method: 'post',
            headers: headerData,
            body: JSON.stringify({
                'nama_ketua': JSON.stringify([namaKetua, 0]),
                'nik_ketua': JSON.stringify([nikKetua, 0]),
                'masa_awal_ketua': masaKetuaMulai,
                'masa_akhir_ketua': masaKetuaAkhir,
                'nama_sekretaris_jendral': JSON.stringify([namaSekretaris, 0]),
                'nik_sekretaris': JSON.stringify([nikSekretaris, 0]),
                'masa_awal_sekretaris': masaSekretarisMulai,
                'masa_akhir_sekretaris': masaSekretarisAkhir,
                'nama_bendahara': JSON.stringify([namaBendahara, 0]),
                'nik_bendahara': JSON.stringify([nikBendahara, 0]),
                'masa_awal_bendahara': masaBendaharaMulai,
                'masa_akhir_bendahara': masaBendaharaAkhir,
                'pendiri': JSON.stringify([pendiri, 0]),
                'pembina': JSON.stringify([pembina, 0]),
                'penasehat': JSON.stringify([penasehat, 0]),
                'id_user': user.id
            })
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                console.log(res)
                if (res.code) {
                    navigate('/user/lkoadministrasi');
                } else {
                    {
                        setSwalProps({
                            show: true,
                            title: 'error'
                        });
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const tambahField = (type) => {
        switch (type) {
            case 'pendiri': setDataPendiri([...pendiri, { nik: '', nama: '' }])
                break;
            case 'pembina': setDataPembina([...pembina, { nik: '', nama: '' }])
                break;
            case 'penasehat': setDataPenasehat([...penasehat, { nik: '', nama: '' }])
                break;
            default:
                return null;
        }
    }

    const handleFieldChange = (index, field, value, type) => {
        switch (type) {
            case 'pendiri': {
                const updatedPendiri = [...pendiri];
                updatedPendiri[index][field] = value;
                setDataPendiri(updatedPendiri);
            }
                break;
            case 'pembina': {
                const updatedPembina = [...pembina];
                updatedPembina[index][field] = value;
                setDataPembina(updatedPembina);
            }
                break;
            case 'penasehat': {
                const updatedPendiri = [...penasehat];
                updatedPendiri[index][field] = value;
                setDataPenasehat(updatedPendiri);
            }
                break;
            default:
                return null;
        }
    };

    const deleteField = (indexToDelete, type) => {
        switch (type) {
            case 'pendiri': {
                const updatedPendiri = pendiri.filter((_, index) => index !== indexToDelete);
                setDataPendiri(updatedPendiri);
            }
                break;
            case 'pembina': {
                const updatedPembina = pembina.filter((_, index) => index !== indexToDelete);
                setDataPembina(updatedPembina);
            }
                break;
            case 'penasehat': {
                const updatedPenasehat = penasehat.filter((_, index) => index !== indexToDelete);
                setDataPenasehat(updatedPenasehat);
            }
                break;
            default:
                return null;
        }
    };

    const getDataUser = () => {
        fetch(process.env.REACT_APP_API_URL + "api/lkokepengurusan/" + user.id, {
            method: 'get',
            headers: headerData
        })
            .then(res => res.json())
            .then(async (data) => {
                if (data) {
                    setData(data);
                    setDataPendiri(JSON.parse(data.pendiri)[0]);
                    setDataPembina(JSON.parse(data.pembina)[0]);
                    setDataPenasehat(JSON.parse(data.penasehat)[0]);

                    setNamaKetua(JSON.parse(data.nama_ketua)[0]);
                    setNikKetua(JSON.parse(data.nik_ketua)[0]);
                    setMasaKetuaMulai(data.masa_awal_ketua);
                    setMasaKetuaAkhir(data.masa_akhir_ketua);

                    setNamaSekretaris(JSON.parse(data.nama_sekretaris_jendral)[0]);
                    setNikSekretaris(JSON.parse(data.nik_sekretaris)[0]);
                    setMasaSekretarisMulai(data.masa_awal_sekretaris);
                    setMasaSekretarisAkhir(data.masa_akhir_sekretaris);

                    setNamaBendahara(JSON.parse(data.nama_bendahara)[0]);
                    setNikBendahara(JSON.parse(data.nik_bendahara)[0]);
                    setMasaBendaharaMulai(data.masa_awal_bendahara);
                    setMasaBendaharaAkhir(data.masa_akhir_bendahara);
                }
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        if (!data) {
            getDataUser()
        }
    }, [data, getDataUser])

    return (
        <Grid
            container
            direction={"column"}
            sx={{
                bgcolor: '#D1E5F4',
            }}
            alignSelf={'center'}
            alignContent={'center'}
            height={'auto'}
        >
            <SweetAlert2 {...swalProps} onConfirm={() => setSwalProps({ show: false })}>
                <h1>
                    {'Terjadi Kesalahan Penginputan'}
                </h1>
            </SweetAlert2>
            <Grid item position={'fixed'}>
                <Button
                    LinkComponent={Link}
                    to='/user/pilihpermohonan'
                >
                    <Typography textTransform={'capitalize'} fontSize={14} sx={{
                        display: 'flex'
                    }}>
                        <ArrowBackOutlined sx={{
                            marginX: '10px'
                        }} /> {'Laporan Keberadaan Ormas'}
                    </Typography>
                </Button>
            </Grid>
            <Box height={'50px'} />
            <Grid item sx={{
                marginX: 2
            }}>
                <Typography
                    textTransform={'capitalize'}
                    fontSize={24}
                    fontWeight={400}
                    color={'#00658B'}
                >
                    Laporan
                </Typography>
                <Typography
                    textTransform={'capitalize'}
                    fontSize={24}
                    fontWeight={400}
                    color={'#00658B'}
                >
                    Keberadaan Ormas
                </Typography>
                <Divider sx={{
                    border: 1,
                    color: '#00658B'
                }} />
            </Grid>
            <Grid item sx={{
                marginTop: 2,
                paddingX: 2
            }}>
                <Card
                    sx={{
                        paddingX: 2,
                        borderRadius: 4
                    }}
                >
                    <Grid container direction={'column'} alignContent={'center'}>
                        <Grid
                            item
                            container
                            direction={'row'}
                            // paddingX={2}
                            marginY={1}
                            sx={{
                                backgroundColor: '#C4E7FF',
                                borderRadius: 8,
                            }}
                        >
                            <Grid item container direction={'row'} justifyContent={'space-between'}>
                                <Grid item>
                                    <Button
                                        LinkComponent={Link}
                                        to={'/user/lkodatapermohonan'}
                                        variant="text" size="small" sx={{
                                            borderRadius: 8,
                                            width: '100%'
                                        }}>
                                        1
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        LinkComponent={Link}
                                        to={'/user/lkokepengurusan'}
                                        variant="contained" size="small" sx={{
                                            background: '#00658B',
                                            ":hover": {
                                                background: '#C4E7FF',
                                            },
                                            borderRadius: 8,
                                            width: '100%'
                                        }}>
                                        2
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        LinkComponent={Link}
                                        to={'/user/lkoadministrasi'}
                                        variant="text" size="small" sx={{
                                            borderRadius: 8,
                                            width: '100%'
                                        }}>
                                        3
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={'column'}
                        >
                            <Grid item sx={{
                                marginY: 2
                            }}>
                                <Typography
                                    textTransform={'capitalize'}
                                    fontWeight={500}
                                    fontSize={'14px'}
                                    lineHeight={'20px'}
                                    color={'#00658B'}
                                    sx={{
                                        fontWeight: 500
                                    }}
                                >
                                    {'Kepengurusan'}
                                </Typography>
                            </Grid>
                            {/* Mulai Ketua */}
                            <Grid item>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'13px'}
                                    fontStyle={'normal'}
                                    fontWeight={700}
                                >
                                    {'Ketua Umum'}
                                </Typography>
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Nama Ketua Umum'}
                                </Typography>
                                <TextField
                                    placeholder="Nama Ketua Umum "
                                    size="small"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={namaKetua}
                                    onChange={(e) => setNamaKetua(e.target.value)}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'NIK'}
                                </Typography>
                                <TextField
                                    placeholder="NIK Ketua"
                                    size="small"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={nikKetua}
                                    onChange={(e) => setNikKetua(e.target.value)}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Masa Bakti Kepengurusan'}
                                </Typography>
                                <Grid container direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <TextField
                                        placeholder="Tanggal Awal"
                                        size="small"
                                        type="date"
                                        label=""
                                        variant="outlined"
                                        value={masaKetuaMulai}
                                        onChange={(ev) => setMasaKetuaMulai(ev.target.value)}
                                    />
                                    <Typography marginX={1} textTransform={'none'}>{' s.d '}</Typography>
                                    <TextField
                                        placeholder="Tanggal Akhir"
                                        size="small"
                                        type="date"
                                        label=""
                                        variant="outlined"
                                        value={masaKetuaAkhir}
                                        onChange={(e) => setMasaKetuaAkhir(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            {/* Batas Ketua */}
                            <Divider sx={{ marginY: 1 }} />
                            {/* Mulai Sekretaris */}
                            <Grid item>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'13px'}
                                    fontStyle={'normal'}
                                    fontWeight={700}
                                >
                                    {'Sekretaris'}
                                </Typography>
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Nama Sekretaris atau Istilah Lain'}
                                </Typography>
                                <TextField
                                    placeholder="Nama Sekretaris atau Istilah Lain"
                                    size="small"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={namaSekretaris}
                                    onChange={(e) => setNamaSekretaris(e.target.value)}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'NIK'}
                                </Typography>
                                <TextField
                                    placeholder="NIK Sekretaris atau Istilah Lain"
                                    size="small"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={nikSekretaris}
                                    onChange={(e) => setNikSekretaris(e.target.value)}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Masa Bakti Kepengurusan'}
                                </Typography>
                                <Grid container direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <TextField
                                        placeholder="Tanggal Awal"
                                        size="small"
                                        type="date"
                                        label=""
                                        variant="outlined"
                                        value={masaSekretarisMulai}
                                        onChange={(e) => setMasaSekretarisMulai(e.target.value)}
                                    />
                                    <Typography marginX={1} textTransform={'none'}>{' s.d '}</Typography>
                                    <TextField
                                        placeholder="Tanggal Akhir"
                                        size="small"
                                        type="date"
                                        label=""
                                        variant="outlined"
                                        value={masaSekretarisAkhir}
                                        onChange={(e) => setMasaSekretarisAkhir(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            {/* Batas Sekretaris */}
                            <Divider sx={{ marginY: 1 }} />
                            {/* Mulai Bendahara */}
                            <Grid item>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'13px'}
                                    fontStyle={'normal'}
                                    fontWeight={700}
                                >
                                    {'Bendahara'}
                                </Typography>
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Nama Bendahara atau Istilah Lain'}
                                </Typography>
                                <TextField
                                    placeholder="Nama Bendahara atau Istilah Lain"
                                    size="small"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={namaBendahara}
                                    onChange={(e) => setNamaBendahara(e.target.value)}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'NIK'}
                                </Typography>
                                <TextField
                                    placeholder="NIK Bendahara atau Istilah Lain"
                                    size="small"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={nikBendahara}
                                    onChange={(e) => setNikBendahara(e.target.value)}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Masa Bakti Kepengurusan'}
                                </Typography>
                                <Grid container direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <TextField
                                        placeholder="Tanggal Awal"
                                        size="small"
                                        type="date"
                                        label=""
                                        variant="outlined"
                                        value={masaBendaharaMulai}
                                        onChange={(e) => setMasaBendaharaMulai(e.target.value)}
                                    />
                                    <Typography marginX={1} textTransform={'none'}>{' s.d '}</Typography>
                                    <TextField
                                        placeholder="Tanggal Akhir"
                                        size="small"
                                        type="date"
                                        label=""
                                        variant="outlined"
                                        value={masaBendaharaAkhir}
                                        onChange={(e) => setMasaBendaharaAkhir(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            {/* Batas Bendahara */}
                            {/* Pendiri */}
                            <Divider sx={{ marginY: 1 }} />
                            <Grid item container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'13px'}
                                    fontStyle={'normal'}
                                    fontWeight={700}
                                >
                                    {'Pendiri'}
                                </Typography>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => tambahField('pendiri')}
                                    sx={{
                                        textTransform: 'none',
                                        backgroundColor: '#C4E7FF',
                                        color: '#00658B',
                                        borderRadius: 6,
                                        ':hover': {
                                            backgroundColor: '#C4E7FF',
                                        }
                                    }}>
                                    + Tambah
                                </Button>
                            </Grid>
                            {pendiri.map((elemen, index) => {
                                return (<Grid key={index} item marginY={1} container direction={'column'}>
                                    <Card
                                        key={index}
                                        sx={{
                                            paddingX: 1,
                                            paddingY: 1,
                                            backgroundColor: '#FBFCFF'
                                        }}>
                                        <Grid item marginY={1}>
                                            <Grid container item justifyContent={'space-between'} >
                                                <Typography
                                                    textTransform={'none'}
                                                    fontSize={'12px'}
                                                    fontStyle={'normal'}
                                                >
                                                    {'Nama'}
                                                </Typography>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => deleteField(index, 'pendiri')}
                                                    sx={{
                                                        textTransform: 'none',
                                                        backgroundColor: '#FFDAD6',
                                                        color: '#BA1A1A',
                                                        borderRadius: 6,
                                                        ':hover': {
                                                            backgroundColor: '#FFDAD6',
                                                        }
                                                    }}>
                                                    - Hapus
                                                </Button>
                                            </Grid>
                                            <TextField
                                                placeholder="Nama Pendiri"
                                                size="small"
                                                type="text"
                                                label=""
                                                variant="outlined"
                                                fullWidth
                                                value={elemen.nama}
                                                onChange={(e) => handleFieldChange(index, 'nama', e.target.value, 'pendiri')}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                textTransform={'none'}
                                                fontSize={'12px'}
                                                fontStyle={'normal'}
                                            >
                                                {'NIK'}
                                            </Typography>
                                            <TextField
                                                placeholder="NIK Pendiri"
                                                size="small"
                                                type="text"
                                                label=""
                                                variant="outlined"
                                                fullWidth
                                                value={elemen.nik}
                                                onChange={(e) => handleFieldChange(index, 'nik', e.target.value, 'pendiri')}
                                            />
                                        </Grid>
                                    </Card>
                                </Grid>)
                            })}
                            <Divider sx={{ marginY: 1 }} />
                            <Grid item container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'13px'}
                                    fontStyle={'normal'}
                                    fontWeight={700}
                                >
                                    {'Pembina'}
                                </Typography>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => tambahField('pembina')}
                                    sx={{
                                        textTransform: 'none',
                                        backgroundColor: '#C4E7FF',
                                        color: '#00658B',
                                        borderRadius: 6,
                                        ':hover': {
                                            backgroundColor: '#C4E7FF',
                                        }
                                    }}>
                                    + Tambah
                                </Button>
                            </Grid>
                            {pembina.map((elemen, index) => {
                                return (<Grid key={index} item marginY={1} container direction={'column'}>
                                    <Card
                                        key={index}
                                        sx={{
                                            paddingX: 1,
                                            paddingY: 1,
                                            backgroundColor: '#FBFCFF'
                                        }}>
                                        <Grid item marginY={1}>
                                            <Grid container item justifyContent={'space-between'} >
                                                <Typography
                                                    textTransform={'none'}
                                                    fontSize={'12px'}
                                                    fontStyle={'normal'}
                                                >
                                                    {'Nama'}
                                                </Typography>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => deleteField(index, 'pembina')}
                                                    sx={{
                                                        textTransform: 'none',
                                                        backgroundColor: '#FFDAD6',
                                                        color: '#BA1A1A',
                                                        borderRadius: 6,
                                                        ':hover': {
                                                            backgroundColor: '#FFDAD6',
                                                        }
                                                    }}>
                                                    - Hapus
                                                </Button>
                                            </Grid>
                                            <TextField
                                                placeholder="Nama Pembina"
                                                size="small"
                                                type="text"
                                                label=""
                                                variant="outlined"
                                                fullWidth
                                                value={elemen.nama}
                                                onChange={(e) => handleFieldChange(index, 'nama', e.target.value, 'pembina')}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                textTransform={'none'}
                                                fontSize={'12px'}
                                                fontStyle={'normal'}
                                            >
                                                {'NIK'}
                                            </Typography>
                                            <TextField
                                                placeholder="NIK Pembina"
                                                size="small"
                                                type="text"
                                                label=""
                                                variant="outlined"
                                                fullWidth
                                                value={elemen.nik}
                                                onChange={(e) => handleFieldChange(index, 'nik', e.target.value, 'pembina')}
                                            />
                                        </Grid>
                                    </Card>
                                </Grid>)
                            })}
                            <Divider sx={{ marginY: 1 }} />
                            <Grid item container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'13px'}
                                    fontStyle={'normal'}
                                    fontWeight={700}
                                >
                                    {'Penasehat'}
                                </Typography>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => tambahField('penasehat')}
                                    sx={{
                                        textTransform: 'none',
                                        backgroundColor: '#C4E7FF',
                                        color: '#00658B',
                                        borderRadius: 6,
                                        ':hover': {
                                            backgroundColor: '#C4E7FF',
                                        }
                                    }}>
                                    + Tambah
                                </Button>
                            </Grid>
                            {penasehat.map((elemen, index) => {
                                return (<Grid key={index} item marginY={1} container direction={'column'}>
                                    <Card
                                        key={index}
                                        sx={{
                                            paddingX: 1,
                                            paddingY: 1,
                                            backgroundColor: '#FBFCFF'
                                        }}>
                                        <Grid item marginY={1}>
                                            <Grid container item justifyContent={'space-between'} >
                                                <Typography
                                                    textTransform={'none'}
                                                    fontSize={'12px'}
                                                    fontStyle={'normal'}
                                                >
                                                    {'Nama'}
                                                </Typography>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => deleteField(index, 'penasehat')}
                                                    sx={{
                                                        textTransform: 'none',
                                                        backgroundColor: '#FFDAD6',
                                                        color: '#BA1A1A',
                                                        borderRadius: 6,
                                                        ':hover': {
                                                            backgroundColor: '#FFDAD6',
                                                        }
                                                    }}>
                                                    - Hapus
                                                </Button>
                                            </Grid>
                                            <TextField
                                                placeholder="Nama Penasehat"
                                                size="small"
                                                type="text"
                                                label=""
                                                variant="outlined"
                                                fullWidth
                                                value={elemen.nama}
                                                onChange={(e) => handleFieldChange(index, 'nama', e.target.value, 'penasehat')}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                textTransform={'none'}
                                                fontSize={'12px'}
                                                fontStyle={'normal'}
                                            >
                                                {'NIK'}
                                            </Typography>
                                            <TextField
                                                placeholder="NIK Penasehat"
                                                size="small"
                                                type="text"
                                                label=""
                                                variant="outlined"
                                                fullWidth
                                                value={elemen.nik}
                                                onChange={(e) => handleFieldChange(index, 'nik', e.target.value, 'penasehat')}
                                            />
                                        </Grid>
                                    </Card>
                                </Grid>)
                            })}
                            <Divider sx={{ marginY: 1 }} />
                            <Grid item container direction={'row'} marginY={1} justifyContent={'space-between'}>
                                <Button
                                    LinkComponent={Link}
                                    to='/user/lkodatapermohonan'
                                    variant="contained"
                                    size="small"
                                    sx={{
                                        borderRadius: 3,
                                        background: '#C4E7FF',
                                        color: '#00658B',
                                        border: 1,
                                        borderColor: '#00658B',
                                        ":hover": {
                                            background: '#C4E7FF',
                                        },
                                        paddingX: 3,
                                        paddingY: 1
                                    }}
                                >
                                    <Typography fontSize={'13px'} textTransform={'capitalize'}>{'< Sebelumnya'}</Typography>
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{
                                        borderRadius: 3,
                                        background: '#00658B',
                                        ":hover": {
                                            background: '#C4E7FF',
                                        },
                                        paddingX: 3,
                                        paddingY: 1
                                    }}
                                    onClick={() => sendData()}
                                >
                                    <Typography fontSize={'13px'} textTransform={'capitalize'}>{'Selanjutnya >'}</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box height={10} />
                </Card>
                <Box height={25} />
            </Grid>
        </Grid >
    );
}

export default LKO_kepengurusan;