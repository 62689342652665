import { Box, Button, Card, Container, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { tokens } from "../../theme";
import { Link } from "react-router-dom";
import Hero from '../../assets/image/hero2.png';
import { ArrowBackOutlined, } from "@mui/icons-material";

const ListPermohonan = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Grid
            container
            direction={"column"}
            sx={{
                bgcolor: '#D1E5F4',
            }}
            alignSelf={'center'}
            alignContent={'center'}
            height={'100vh'}
        // maxWidth={'sm'}
        >
            <Grid item position={'fixed'}>
                <Button
                    LinkComponent={Link}
                    to='/user/beranda'
                >
                    <Typography textTransform={'capitalize'} fontSize={14} sx={{
                        display: 'flex'
                    }}>
                        <ArrowBackOutlined sx={{
                            marginX: '10px'
                        }} /> {'Buat permohonan'}
                    </Typography>
                </Button>
            </Grid>
            <Box height={'50px'} />
            <Grid item>
                <img src={Hero} alt="Hero" width={'100%'} />
            </Grid>
            <Grid item>
                <Typography
                    textTransform={'capitalize'}
                    fontSize={24}
                    fontWeight={400}
                    color={'#00658B'}
                >
                    Buat Permohonan
                </Typography>
                <Typography
                    textTransform={'capitalize'}
                    fontSize={12}
                    fontWeight={400}
                    color={'#191C1E'}
                >
                    Pilih Layanan Administrasi
                </Typography>
            </Grid>
            <Grid item sx={{
                marginTop: 2,
                paddingX: 2
            }}>
                <Button
                variant="outlined" 
                LinkComponent={Link}
                to={'/user/sktdatapermohonan'}
                sx={{
                    width: '100%',
                    border: '0px',
                    borderRadius: '16px',
                    paddingY: '10px',
                    marginY: '10px',
                    bgcolor: '#FBFCFF',
                }}>
                    <Typography textTransform={"capitalize"}>{'Surat Keterangan Terdaftar Bagi Ormas >'}</Typography>
                </Button>
            </Grid>
            <Grid item sx={{
                paddingX: 2
            }}>
                <Button variant="outlined" 
                LinkComponent={Link}
                to={'/user/lkodatapermohonan'}
                sx={{
                    width: '100%',
                    border: '0px',
                    borderRadius: '16px',
                    paddingY: '10px',
                    marginY: '10px',
                    bgcolor: '#FBFCFF',
                }}>
                    <Typography textTransform={"capitalize"}>{'laporan Keberadaan Ormas >'}</Typography>
                </Button>
            </Grid>
        </Grid >
    );
}

export default ListPermohonan;