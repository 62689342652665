import { Avatar, Button, Card, Chip, Grid, Stack, Typography } from "@mui/material";
import BasicTimeline from "./timeline";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import markerIcon from '../../../assets/image/marker-icon.png';
import L from 'leaflet';
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { headerData } from "../../../data/headerCostum";

const timeline = [
    {
        name: 'Permohonan Layanan',
        status: 1,
        desc: 'Menunggu Pembuatan Permohonan'
    },
    {
        name: 'Verifikasi',
        status: 0,
        desc: 'Menunggu Perubahan Status'
    },
    {
        name: 'Berkas Lengkap',
        status: 0,
        desc: 'Menunggu Perubahan Status'
    },
    {
        name: 'Proses Unit Kerja',
        status: 0,
        desc: 'Menunggu Perubahan Status'
    },
    {
        name: 'Selesai',
        status: 0,
        desc: 'Menunggu Perubahan Status',
        akhir: 1
    },
]

const column = [
    {
        id: 'pengesahan_akte',
        label: 'Pengesahan akte pendirian dari Kementerian Hukum dan HAM Republik Indonesia atau Legalitas Kelembagaan atau dasar Pembentukan Organisasi'
    },
    {
        id: 'akte_pendirian',
        label: 'Akte Pendirian atau status orkemas yang disahkan Notaris'
    },
    {
        id: 'adart',
        label: 'Anggaran Dasar dan Anggaran Rumah Tangga yang disahkan notaris'
    },
    {
        id: 'tujuan_program',
        label: 'Tujuan dan Program Kerja organisasi yang ditanda tangani oleh Ketua dan Sekretaris'
    },
    {
        id: 'sk_spo',
        label: 'Surat Keputusan tentang Susunan Pengurus Orkemas secara lengkap yang sah sesuai anggaran dasar dan anggran rumah tangga '
    },
    {
        id: 'file_biodata_ketua',
        label: 'File Scan Biodata Ketua '
    },
    {
        id: 'foto_ketua',
        label: 'File Scan Foto Ketua Berwarna 4x6 (3 bulan Terakhir)'
    },
    {
        id: 'file_ktp_ketua',
        label: 'File Scan KTP Ketua'
    },
    {
        id: 'file_biodata_sekretaris',
        label: 'File Scan Biodata Sekretaris atau Sebutan Lain'
    },
    {
        id: 'foto_sekretaris',
        label: 'File Scan Foto Sekretaris Berwarna 4x6 (3 bulan Terakhir)'
    },
    {
        id: 'file_ktp_sekretaris',
        label: 'File Scan KTP Sekretaris'
    },
    {
        id: 'file_biodata_bendahara',
        label: 'File Scan Biodata Bendahara atau Sebutan Lain'
    },
    {
        id: 'foto_bendahara',
        label: 'File Scan Foto Bendahara Berwarna 4x6 (3 bulan Terakhir)'
    },
    {
        id: 'file_ktp_bendahara',
        label: 'File Scan KTP Bendahara'
    },
    {
        id: 'sk_domisili',
        label: 'Surat keterangan domisili organisasi dari Lurah / Camat dimana Organisasi berdomisili'
    },
    {
        id: 'foto_kantor',
        label: 'Foto Kantor atau Sekretariat Orkemas, tampak depan yang memuat papan nama Organisasi'
    },
    {
        id: 'keabsahan_kantor',
        label: 'Kebsahan kantor atau sekretariat orkemas dilampiri bukti kepemilikan, atau surat perjanjian kontrak atau ijin pakai dari pemilik / pengelola'
    },
    {
        id: 'sp_tidak_konflik',
        label: 'Surat Pernyataan tidak terjadi Konflik kepengurusan, yang ditandatangani oleh Ketua dan Sekretaris, atau sebutan lainnya.'
    }
]


const center = {
    lat: -5.160543,
    lng: 119.436077,
}

const myIcon = new L.Icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon,
    popupAnchor: [-0, -0],
    iconSize: [50, 60],
});

const DetailPermohonanLKO = () => {
    const { idLKO } = useParams();
    const [ormasLocation, setOrmasLocation] = useState(center)
    const [dataPermohonan, setDataPermohonan] = useState(null)
    const [dataKepengurursan, setKepengurusan] = useState(null)
    const [dataStatus, setDataStatus] = useState(null);
    const [dataTimeLine, setDataTimeLine] = useState(timeline);
    const [dataUser, setDataUser] = useState(null);
    const [dataAdministrasi, SetDataAdministrasi] = useState(null)

    const getDataStatus = () => {
        fetch(process.env.REACT_APP_API_URL + "api/tracking_permohonan/" + idLKO, {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                if(data.id){
                    setDataStatus(data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getDataPermohonan = () => {
        fetch(process.env.REACT_APP_API_URL + "api/lkodatapermohonan/" + idLKO, {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                if(data.id){
                    setDataUser(data.Pendaftar)
                    setDataPermohonan(data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getDataKepengurursan = () => {
        fetch(process.env.REACT_APP_API_URL + "api/lkokepengurusan/" + idLKO, {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                if (data.id) {
                    if (data.alamat_sekret) {
                        setOrmasLocation(JSON.parse(data.alamat_sekret)[0])
                    }
                    setKepengurusan(data)
                }
            })
            .catch(err => {
                setKepengurusan(null)
                setOrmasLocation(center)
                console.log(err)
            })
    }

    const getDataAdministrasi = () => {
        fetch(process.env.REACT_APP_API_URL + "api/lkoadministrasi/" + idLKO, {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                if (data.id) {
                    SetDataAdministrasi(data)
                }
            })
            .catch(err => {
                console.log(err);
                SetDataAdministrasi(null)
            })
    }

    useEffect(() => {
        if(!dataStatus){
            getDataStatus();
        }
        if (!dataPermohonan) {
            getDataPermohonan()
        }
        if (!dataKepengurursan) {
            getDataKepengurursan()
        }
        if (!dataAdministrasi) {
            getDataAdministrasi()
        }
    }, [dataPermohonan, dataKepengurursan, dataAdministrasi, getDataPermohonan, getDataKepengurursan, getDataAdministrasi])

    return (
        <Grid container direction={'row'}>
            <Grid item container md={4} direction={'column'}>
                <Grid item container direction={'column'} sx={{
                    backgroundColor: '#FBFCFF',
                    padding: 2,
                    borderRadius: '16px'
                }}>
                    <Grid item container justifyContent={'space-between'}>
                        <Typography variant="h4">
                            {'Status'}
                        </Typography>
                        <Button variant="contained" sx={{
                            backgroundColor: "#00658B",
                            textTransform: 'none',
                        }}>
                            {'Verifikasi Permohonan'}
                        </Button>
                    </Grid>
                    <Grid item>
                        <BasicTimeline data={timeline} />
                    </Grid>
                </Grid>
                <Grid item container direction={'column'} sx={{
                    backgroundColor: '#FBFCFF',
                    padding: 2,
                    borderRadius: '16px',
                    marginTop: 1,
                    marginBottom: 1
                }}>
                    <Grid item container rowSpacing={2} direction={'column'}>
                        <Grid item>
                            <Typography variant="h4">
                                {'Data Permohonan'}
                            </Typography>
                        </Grid>
                        <Grid item container direction={'row'} justifyContent={'space-between'}>
                            <Grid item>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Avatar alt="Remy Sharp" />
                                    <Grid container direction={'column'}>
                                        <Typography>
                                            {'Akun Pemohon'}
                                        </Typography>
                                        <Typography fontWeight={700}>
                                            {dataUser ? dataUser.nama : ''}
                                        </Typography>
                                    </Grid>
                                </Stack>
                            </Grid>
                            <Grid item >
                                <Button
                                    LinkComponent={Link}
                                    to={'/admin/user/' + idLKO}
                                    variant='contained'
                                    size='small'
                                    sx={{
                                        backgroundColor: "#00658B",
                                        textTransform: 'none',
                                    }}>
                                    {'Lihat User'}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item container direction={'row'} justifyContent={'space-between'}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Grid container direction={'column'}>
                                    <Typography>
                                        {'Nomor Surat Permohonan'}
                                    </Typography>
                                    <Typography variant="h4">
                                        {dataPermohonan ? JSON.parse(dataPermohonan.no_surat)[0] : ''}
                                    </Typography>
                                </Grid>
                            </Stack>
                        </Grid>
                        <Grid item container direction={'row'} justifyContent={'space-between'}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Grid container direction={'column'}>
                                    <Typography>
                                        {'Tanggal Surat Permohonan'}
                                    </Typography>
                                    <Typography variant="h4">
                                        {dataPermohonan ? JSON.parse(dataPermohonan.tanggal_surat)[0] : ''}
                                    </Typography>
                                </Grid>
                            </Stack>
                        </Grid>
                        <Grid item container direction={'row'} justifyContent={'space-between'}>
                            <Grid item>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Grid container direction={'column'}>
                                        <Typography>
                                            {'Scan Surat Permohonan'}
                                        </Typography>
                                        <Typography fontWeight={700}>
                                            {
                                                // dataPermohonan ? JSON.parse(dataPermohonan.scan_surat)[0] : ''
                                            }
                                        </Typography>
                                    </Grid>
                                </Stack>
                            </Grid>
                            <Grid item >
                                <Button
                                    component={Link}
                                    // to={
                                    //     // process.env.REACT_APP_API_URL + 'api/' + (dataPermohonan ? JSON.parse(dataPermohonan.scan_surat)[0] : '')
                                    // }
                                    target="_blank"
                                    size='small'
                                    variant='contained'
                                    sx={{
                                        backgroundColor: "#00658B",
                                        textTransform: 'none',
                                    }}
                                >
                                    {'Lihat File'}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item container direction={'row'} justifyContent={'space-between'}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Grid container direction={'column'}>
                                    <Typography>
                                        {'Nama Ormas'}
                                    </Typography>
                                    <Typography variant="h4">
                                        {dataPermohonan ? JSON.parse(dataPermohonan.nama_ormas)[0] : ''}
                                    </Typography>
                                </Grid>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container md={4}>
                <Grid item container direction={'column'} sx={{
                    backgroundColor: '#FBFCFF',
                    padding: 2,
                    borderRadius: '16px',
                    marginLeft: 1,
                    marginBottom: 1
                }}>
                    <Grid item container>
                        <Typography variant="h4">
                            {'Sekretariat'}
                        </Typography>
                    </Grid>
                    <Grid item container marginTop={2}>
                        <MapContainer center={ormasLocation} zoom={13} scrollWheelZoom={true} style={{ height: '250px', width: "100%" }}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker
                                draggable={false}
                                position={ormasLocation}
                                icon={myIcon}
                            >
                                <Popup minWidth={90}>
                                    <span>
                                        {'Pilih Lokasi Anda'}
                                    </span>
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </Grid>
                    <Grid item container direction={"column"} marginTop={2}>
                        <Grid item>
                            <Typography variant="body1">
                                {'Alamat Sekretariat'}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">
                                {dataPermohonan
                                    ? JSON.parse(dataPermohonan.detail_alamat)[0]
                                    : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction={"column"} marginTop={2}>
                        <Grid item>
                            <Typography variant="body1">
                                {'Nama Ketua'}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">
                                {dataKepengurursan ? JSON.parse(dataKepengurursan.nama_ketua)[0] : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction={"column"} marginTop={2}>
                        <Grid item>
                            <Typography variant="body1">
                                {'NIK Ketua'}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">
                                {dataKepengurursan ? JSON.parse(dataKepengurursan.nik_ketua)[0] : ''}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item container direction={"column"} marginTop={2}>
                        <Grid item>
                            <Typography variant="body1">
                                {'Nama Bendahara'}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">
                                {dataKepengurursan ? JSON.parse(dataKepengurursan.nama_bendahara)[0] : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction={"column"} marginTop={2}>
                        <Grid item>
                            <Typography variant="body1">
                                {'NIK Bendahara'}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">
                                {dataKepengurursan ? JSON.parse(dataKepengurursan.nik_bendahara)[0] : ''}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item container direction={"column"} marginTop={2}>
                        <Grid item>
                            <Typography variant="body1">
                                {'Nama Sekretaris'}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">
                                {/* {dataKepengurursan ? JSON.parse(dataKepengurursan.nama_sekretaris)[0] : ''} */}
                                {dataKepengurursan ? JSON.parse(dataKepengurursan.nama_sekretaris_jendral)[0] : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction={"column"} marginTop={2}>
                        <Grid item>
                            <Typography variant="body1">
                                {'NIK Sekretaris'}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">
                                {dataKepengurursan ? JSON.parse(dataKepengurursan.nik_sekretaris)[0] : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container md={4}>
                <Grid item container direction={'column'} sx={{
                    backgroundColor: '#FBFCFF',
                    padding: 2,
                    borderRadius: '16px',
                    marginLeft: 1
                }}>
                    <Grid item container direction={'column'} spacing={2}>
                        <Grid item>
                            <Typography variant="h4">
                                {'Kelengkapan Administrasi'}
                            </Typography>
                        </Grid>
                    </Grid>

                    {
                        dataAdministrasi
                            ? column.map((item) => (
                                <Grid item key={item.id} container marginTop={2} direction={'row'} justifyContent={'space-between'}>
                                    <Grid item>
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <Grid container direction={'column'}>
                                                <Typography>
                                                    {item.label}
                                                </Typography>
                                                <Typography fontWeight={700} width={150} variant="body2" textOverflow={'inherit'}>
                                                    {dataAdministrasi ? JSON.parse(dataAdministrasi[item.id]) ? JSON.parse(dataAdministrasi[item.id])[0] : '' : ''}
                                                </Typography>
                                            </Grid>
                                        </Stack>
                                    </Grid>
                                    <Grid item >
                                        {
                                            dataAdministrasi
                                                ? JSON.parse(dataAdministrasi[item.id])
                                                    ? <Button
                                                        component={Link}
                                                        to={process.env.REACT_APP_API_URL + 'api/' + JSON.parse(dataAdministrasi[item.id])[0]}
                                                        target="_blank"
                                                        size='small'
                                                        variant='contained'
                                                        sx={{
                                                            backgroundColor: "#00658B",
                                                            textTransform: 'none',
                                                        }}
                                                    >
                                                        {'Lihat File'}
                                                    </Button>
                                                    : <Chip label={'Belum Upload'} variant="filled" color="warning" />
                                                : ""
                                        }
                                    </Grid>
                                </Grid>
                            ))
                            : <></>
                    }
                </Grid>
            </Grid>
        </Grid >
    )
}

export default DetailPermohonanLKO;