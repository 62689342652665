import { Box, Button, Card, Dialog, DialogActions, DialogTitle, Divider, Grid, TextField, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { tokens } from "../../theme";
import { Link, useNavigate } from "react-router-dom";
import MapDialog from "./map";
import { styled } from '@mui/material/styles';
import { ArrowBackOutlined, PersonPinCircleRounded, } from "@mui/icons-material";
import { headerData, headerFormData } from "../../data/headerCostum";
import SweetAlert2 from 'react-sweetalert2';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const center = {
    lat: -5.160543,
    lng: 119.436077,
}

const LKO_data_permohonan = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    const [dialogMapOpen, setDialogMapOpen] = useState(false);
    const [position, setPosition] = useState(center)
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState(null);

    const [nosurat, setNoSurat] = useState('');
    const [tanggal_surat, setTanggalSurat] = useState('');
    const [scan_surat, setScanSurat] = useState(null);
    const [nama_ormas, setNamaOrmas] = useState(user.nama_ormas);
    const [detail_alamat, setDetailAlamat] = useState('');

    const [swalProps, setSwalProps] = useState({});

    const getDataUser = () => {
        fetch(process.env.REACT_APP_API_URL + "api/lkodatapermohonan/" + user.id, {
            method: 'get',
            headers: headerData
        })
            .then(res => res.json())
            .then((data) => {
                if (data) {
                    setData(data);
                    setNoSurat(JSON.parse(data.no_surat)[0]);
                    setTanggalSurat(JSON.parse(data.tanggal_surat)[0]);
                    setDetailAlamat(JSON.parse(data.detail_alamat)[0]);
                }
            })
            .catch((err) => console.log(err))
    }

    const sendData = () => {
        let formData = new FormData();
        formData.append('no_surat', JSON.stringify([nosurat, 0]));
        formData.append('tanggal_surat', JSON.stringify([tanggal_surat, 0]));
        formData.append('nama_ormas', JSON.stringify([nama_ormas, 0]));
        formData.append('alamat_sekret', JSON.stringify([position, 0]));
        formData.append('detail_alamat', JSON.stringify([detail_alamat, 0]));
        formData.append('scan_surat', scan_surat ? scan_surat[0] : null);
        formData.append('id_user', user.id);

        fetch(process.env.REACT_APP_API_URL + "api/lkodatapermohonan", {
            method: 'post',
            headers: headerFormData,
            body: formData
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                if (res.code == 200) {
                    navigate('/user/lkokepengurusan');
                } else {
                    {
                        setSwalProps({
                            show: true,
                            title: 'error'
                        });
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (!data) {
            getDataUser()
        }
    }, [data, getDataUser])


    return (
        <Grid
            container
            direction={"column"}
            sx={{
                bgcolor: '#D1E5F4',
            }}
            alignSelf={'center'}
            alignContent={'center'}
            height={'auto'}
        >
            <SweetAlert2 {...swalProps}>
                <h1>
                    {'Terjadi Kesalahan Penginputan'}
                </h1>
            </SweetAlert2>
            <Dialog onClose={() => setDialogMapOpen(false)} open={dialogMapOpen} fullWidth>
                <DialogTitle>
                    <Typography
                        variant="h5"
                        fontWeight={700}
                        textTransform={'capitalize'}
                    >
                        {'Pilih Point Alamat'}
                    </Typography>
                </DialogTitle>
                <Box height={'60vh'} width={'100%'} sx={{ paddingX: 2 }}>
                    <MapDialog pos={position} execute={setPosition} />
                </Box>
                <DialogActions>
                    <Button
                        variant="contained"
                        sx={{
                            textTransform: 'none',
                        }}
                        onClick={() => setDialogMapOpen(false)}
                    >
                        {'Tutup'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item position={'fixed'}>
                <Button
                    LinkComponent={Link}
                    to='/user/pilihpermohonan'
                >
                    <Typography textTransform={'capitalize'} fontSize={14} sx={{
                        display: 'flex'
                    }}>
                        <ArrowBackOutlined sx={{
                            marginX: '10px'
                        }} /> {'Laporan Keberadaan Ormas'}
                    </Typography>
                </Button>
            </Grid>
            <Box height={'50px'} />
            <Grid item sx={{
                marginX: 2
            }}>
                <Typography
                    textTransform={'capitalize'}
                    fontSize={24}
                    fontWeight={400}
                    color={'#00658B'}
                >
                    Laporan
                </Typography>
                <Typography
                    textTransform={'capitalize'}
                    fontSize={24}
                    fontWeight={400}
                    color={'#00658B'}
                >
                    Keberadaan Ormas
                </Typography>
                <Divider sx={{
                    border: 1,
                    color: '#00658B'
                }} />
            </Grid>
            <Grid item sx={{
                marginTop: 2,
                paddingX: 2
            }}>
                <Card
                    sx={{
                        paddingX: 2,
                        borderRadius: 4
                    }}
                >
                    <Grid container direction={'column'} alignContent={'center'}>
                        <Grid
                            item
                            container
                            direction={'row'}
                            // paddingX={2}
                            marginY={1}
                            sx={{
                                backgroundColor: '#C4E7FF',
                                borderRadius: 8,
                            }}
                        >
                            <Grid item container direction={'row'} justifyContent={'space-between'}>
                                <Grid item>
                                    <Button
                                        LinkComponent={Link}
                                        to={'/user/lkodatapermohonan'}
                                        variant="contained" size="small" sx={{
                                            background: '#00658B',
                                            ":hover": {
                                                background: '#C4E7FF',
                                            },
                                            borderRadius: 8,
                                            width: '100%'
                                        }}>
                                        1
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        LinkComponent={Link}
                                        to={'/user/lkokepengurusan'}
                                        variant="text" size="small" sx={{
                                            borderRadius: 8,
                                            width: '100%'
                                        }}>
                                        2
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        LinkComponent={Link}
                                        to={'/user/lkoadministrasi'}
                                        variant="text" size="small" sx={{
                                            borderRadius: 8,
                                            width: '100%'
                                        }}>
                                        3
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={'column'}
                        >
                            <Grid item sx={{
                                marginY: 2
                            }}>
                                <Typography
                                    textTransform={'capitalize'}
                                    fontWeight={500}
                                    fontSize={'14px'}
                                    lineHeight={'20px'}
                                    color={'#00658B'}
                                >
                                    {'Data Permohonan'}
                                </Typography>
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'No. Surat Permohonan'}
                                </Typography>
                                <TextField
                                    placeholder="Nomor Surat Permohonan"
                                    size="small"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={nosurat}
                                    onChange={(ev) => setNoSurat(ev.target.value)}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Tanggal Surat Permohonan'}
                                </Typography>
                                <TextField
                                    placeholder="Tanggal Surat"
                                    size="small"
                                    type="date"
                                    fullWidth
                                    label=""
                                    variant="outlined"
                                    value={tanggal_surat}
                                    onChange={(ev) => setTanggalSurat(ev.target.value)}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Scan Surat Permohonan'}
                                </Typography>
                                <TextField
                                    placeholder="Nama File"
                                    size="small"
                                    type="text"
                                    label=""
                                    variant="outlined"
                                    value={scan_surat ? scan_surat[0].name : ''}
                                />
                                <Button
                                    sx={{
                                        backgroundColor: '#00658B',
                                        ':hover': {
                                            backgroundColor: '#00658B',
                                        },
                                        textTransform: "capitalize",
                                    }}
                                    size="medium" component="label" variant="contained">
                                    Pilih File
                                    <VisuallyHiddenInput type="file" name="image" onChange={
                                        (ev) => {
                                            if (ev.target.files[0]) {
                                                setScanSurat(ev.target.files)
                                            } else {
                                                setScanSurat(null)
                                            }
                                        }
                                    } />
                                </Button>

                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Nama Ormas'}
                                </Typography>
                                <TextField
                                    placeholder="Masukkan Nama Ormas"
                                    size="small"
                                    type="text"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    value={nama_ormas}
                                    onChange={(ev) => setNamaOrmas(ev.target.value)}
                                />
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Alamat Sekretariat'}
                                </Typography>
                                <TextField
                                    value={position.lat + ', ' + position.lng}
                                    placeholder="Masukkan Latitude"
                                    size="small"
                                    type="text"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                />
                                <Button
                                    onClick={() => setDialogMapOpen(true)}
                                    sx={{
                                        textTransform: 'none',
                                        backgroundColor: '#C4E7FF',
                                        color: '#00658B',
                                        border: 1,
                                        borderColor: '#00658B',
                                        borderRadius: 8
                                    }} variant="contained" fullWidth>
                                    Pilih Titik Lokasi <PersonPinCircleRounded sx={{ marginLeft: 1 }} />
                                </Button>
                            </Grid>
                            <Grid item marginY={1}>
                                <Typography
                                    textTransform={'none'}
                                    fontSize={'12px'}
                                    fontStyle={'normal'}
                                >
                                    {'Detail Alamat'}
                                </Typography>
                                <TextField
                                    placeholder="Masukkan Detail Alamat"
                                    size="small"
                                    type="text"
                                    label=""
                                    variant="outlined"
                                    fullWidth
                                    multiline={true}
                                    rows={3}
                                    value={detail_alamat}
                                    onChange={(ev) => setDetailAlamat(ev.target.value)}
                                />
                            </Grid>
                            <Grid item container direction={'row'} marginY={1} justifyContent={'end'}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => sendData()}
                                    sx={{
                                        borderRadius: 3,
                                        background: '#00658B',
                                        ":hover": {
                                            background: '#C4E7FF',
                                        },
                                        paddingX: 5,
                                        paddingY: 1
                                    }}
                                >
                                    <Typography fontSize={'13px'} textTransform={'capitalize'}>{'Selanjutnya >'}</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box height={10} />
                </Card>
                <Box height={25} />
            </Grid>
        </Grid >
    );
}

export default LKO_data_permohonan;