import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useLocalStorage("user", null);
    const [admin, setAdmin] = useLocalStorage("admin", null);
    const navigate = useNavigate();

    // call this function when you want to authenticate the user
    const login = async (data) => {
        setAdmin(data);
        navigate("/admin");
    };

    const loginUser = async (data) => {
        setUser(data);
        navigate("/user");
    };

    // call this function to sign out logged in user
    const logout = () => {
        setAdmin(null);
        navigate("/login", { replace: true });
    };

    const logoutUser = () => {
        setUser(null);
        navigate("/", { replace: true });
    }

    const value = useMemo(
        () => ({
            user,
            admin,
            login,
            logout,
            loginUser,
            logoutUser
        }),
        [user, admin]
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};