import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, Chip, Grid, IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import ProgressCircle from "../../../components/ProgressCircle";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { headerData } from '../../../data/headerCostum';
import { Link } from 'react-router-dom';
import { PieChart } from '@mui/x-charts/PieChart';

const StatusConverter = (value) => {
    let string;
    switch (value) {
        case '0': string = { color: 'error', text: 'belum ada' }; break;
        case '1': string = { color: 'primary', text: 'permohonan layanan' }; break;
        case '2': string = { color: 'warning', text: 'verifikasi' }; break;
        case '3': string = { color: 'success', text: 'selesai' }; break;
        default: string = null;
    }
    return (
        <>
            {
                string
                    ? <Chip label={string.text} color={string.color} variant='outlined' />
                    : '...'
            }
        </>
    )
}

const ShowPin = (value, show, execute) => (
    <TextField
        size='small'
        type={show[value] ? 'text' : 'password'}
        variant='standard'
        value={value}
        fullWidth
        sx={{
            background: '#D1E5F4',
            borderRadius: 2,
            width: '130px',
            paddingLeft: 1
        }}
        InputProps={{
            endAdornment: (
                <InputAdornment>
                    <Button
                        onClick={() => {
                            show[value]
                                ? execute({ ...show, [value]: !show[value] })
                                : execute({ ...show, [value]: true })
                        }}
                        size='small' variant='text'
                    >
                        {
                            show[value]
                                ? <VisibilityOffIcon sx={{
                                    color: '#00658B'
                                }} />
                                : <VisibilityIcon
                                    sx={{
                                        color: '#00658B'
                                    }}
                                />
                        }
                    </Button>
                </InputAdornment>
            ),
        }}
    />
)

const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const pallete_permohonan = [colors.blueAccent[500], colors.blueAccent[600]]
    const pallete_user = ['#615A7C', '#E7DEFF']
    const pallete_total_permohonan = ['#006E1C', '#94F990']
    const pallete_total_user = ['#785900', '#FFDF9E']
    const permohonan = [75, 25];
    const [data_pendaftar, setDataPendaftar] = useState([])
    const [pendaftar_baru, setPendaftarBaru] = useState([])
    const [data_permohonan, setDataPermohonan] = useState([])
    const [permohonan_baru, setPermohonanBaru] = useState([])

    // for PIN
    const [showref, setShowRef] = useState({})

    const getDataPendaftar = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/pendaftar", {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                setDataPendaftar(data)
                var user_baru = []
                data.forEach(element => {
                    let date_pendaftar = new Date(element.createdAt)
                    let date_now = new Date(Date.now())
                    if (date_pendaftar.getDay() === date_now.getDay()) {
                        setPendaftarBaru(...pendaftar_baru, [element])
                    }
                });
            })
            .catch(err => {
                console.log(err)
            })
    }, []);

    const getDataPermohonan = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "api/lkodatapermohonan", {
            method: 'get',
            headers: headerData
        })
            .then(res => {
                return res.json()
            })
            .then(data => {
                setDataPermohonan(data)
                data.forEach(element => {
                    let date_permohonan = new Date(element.createdAt)
                    let date_now = new Date(Date.now())
                    if (date_permohonan.getDay() === date_now.getDay()) {
                        setPermohonanBaru(...permohonan_baru, [element])
                    }
                });
            })
            .catch(err => {
                console.log(err)
            })
    }, [])


    useEffect(() => {
        if (data_pendaftar.length === 0) {
            getDataPendaftar()
        }
        if (data_permohonan.length === 0) {
            getDataPermohonan()
        }
    }, [data_pendaftar, getDataPendaftar, data_permohonan, getDataPermohonan])


    return (
        <Grid container direction={'column'}>
            <Grid container item>
                {/* GRID & CHARTS */}
                <Box
                    display="inline-flex"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridAutoRows="250px"
                    gap="20px"
                    width={'100vw'}
                    overflow={'auto'}
                    paddingBottom={2}
                >
                    {/* ROW 1 */}
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} fontWeight={600} color="text.secondary" gutterBottom>
                                Permohonan Hari ini
                            </Typography>
                        </CardContent>
                        <PieChart
                            colors={pallete_permohonan}
                            series={[
                                {
                                    data: [
                                        // { id: 0, value: permohonan[0], label: permohonan[0] + '\n Disetujui' },
                                        { id: 1, value: permohonan_baru.length, label: (permohonan_baru.length) + '\n Permohonan' },
                                    ],
                                    highlightScope: { faded: 'global', highlighted: 'item' },
                                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                    innerRadius: 50,
                                    outerRadius: 80,
                                    paddingAngle: 5,
                                    cornerRadius: 2,
                                    startAngle: -180,
                                    endAngle: 180,
                                    cx: 100,
                                    cy: 80,
                                },
                            ]}
                            width={320}
                            height={200}
                        />
                    </Card>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} fontWeight={600} color="text.secondary" gutterBottom>
                                User Baru Hari ini
                            </Typography>
                        </CardContent>
                        <PieChart
                            colors={pallete_user}
                            series={[
                                {
                                    data: [
                                        // { id: 0, value: permohonan[0], label: permohonan[0] + '\n Disetujui' },
                                        { id: 1, value: pendaftar_baru.length, label: (pendaftar_baru.length) + '\n Pendaftar' },
                                    ],
                                    highlightScope: { faded: 'global', highlighted: 'item' },
                                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                    innerRadius: 50,
                                    outerRadius: 80,
                                    paddingAngle: 5,
                                    cornerRadius: 2,
                                    startAngle: -180,
                                    endAngle: 180,
                                    cx: 100,
                                    cy: 80,
                                },
                            ]}
                            width={320}
                            height={200}
                        />
                    </Card>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} fontWeight={600} color="text.secondary" gutterBottom>
                                Total Permohonan
                            </Typography>
                        </CardContent>
                        <PieChart
                            colors={pallete_total_permohonan}
                            series={[
                                {
                                    data: [
                                        // { id: 0, value: permohonan[0], label: permohonan[0] + '\n Disetujui' },
                                        { id: 1, value: data_permohonan.length, label: (data_permohonan.length) + '\n Permohonan' },
                                    ],
                                    highlightScope: { faded: 'global', highlighted: 'item' },
                                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                    innerRadius: 50,
                                    outerRadius: 80,
                                    paddingAngle: 5,
                                    cornerRadius: 2,
                                    startAngle: -180,
                                    endAngle: 180,
                                    cx: 100,
                                    cy: 80,
                                },
                            ]}
                            width={320}
                            height={200}
                        />
                    </Card>
                    <Card key={'total_user'} sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} fontWeight={600} color="text.secondary" gutterBottom>
                                Total User
                            </Typography>
                        </CardContent>
                        <PieChart
                            colors={pallete_total_user}
                            series={[
                                {
                                    data: [
                                        { id: 0, value: data_pendaftar.length, label: data_pendaftar.length + '\n Pendafatar' },
                                        // { id: 1, value: 0, label: (0) + '\n Terkonfirmasi' },
                                    ],
                                    highlightScope: { faded: 'global', highlighted: 'item' },
                                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                    innerRadius: 50,
                                    outerRadius: 80,
                                    paddingAngle: 5,
                                    cornerRadius: 2,
                                    startAngle: -180,
                                    endAngle: 180,
                                    cx: 100,
                                    cy: 80,
                                },
                            ]}
                            width={320}
                            height={200}
                        />
                    </Card>
                </Box>
            </Grid>
            <Grid item container marginTop={2}>
                <Grid item sm={12} md={6} lg={6} paddingRight={'10px'}>
                    <Box
                        display={'inline-grid'}
                        height={'500px'}
                        overflow={'auto'}
                        paddingBottom={2}
                    >
                        <Card>
                            <Grid container direction={'row'} justifyContent={'space-between'} paddingX={2} marginTop={2}>
                                <Grid item>
                                    <Typography fontWeight={600} variant='h5'>Permohonan Terbaru</Typography>
                                </Grid>
                                <Grid item>
                                    <Button sx={{ color: '#00658B' }} variant='text'>Lihat Semua</Button>
                                </Grid>
                            </Grid>
                            <TableContainer sx={{ paddingX: 1 }}>
                                <Table>
                                    <TableHead sx={{ background: '#C4E7FF' }}>
                                        <TableRow>
                                            <TableCell>
                                                Tanggal
                                            </TableCell>
                                            <TableCell>
                                                No. Permohonan
                                            </TableCell>
                                            <TableCell>
                                                Nama Ormas
                                            </TableCell>
                                            <TableCell>
                                                Nama User
                                            </TableCell>
                                            <TableCell>
                                                Status
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            permohonan_baru.length > 0
                                                ? permohonan_baru.map((elem) => (
                                                    <TableRow>
                                                        <TableCell>
                                                            {elem.createdAt}
                                                        </TableCell>
                                                        <TableCell>
                                                            {JSON.parse(elem.no_surat)[0]}
                                                        </TableCell>
                                                        <TableCell>
                                                            {JSON.parse(elem.nama_ormas)[0]}
                                                        </TableCell>
                                                        <TableCell>
                                                            {elem.Pendaftar.nama}
                                                        </TableCell>
                                                        <TableCell>
                                                            {StatusConverter(elem.Pendaftar.status)}
                                                        </TableCell>
                                                        <TableCell
                                                            align='center'
                                                            key={elem.id}
                                                        >
                                                            <Grid item>
                                                                <Button
                                                                    LinkComponent={Link}
                                                                    to={'/admin/permohonan/lko/' + elem['id'].toString()}
                                                                    variant='text'
                                                                    size='small'
                                                                    sx={{
                                                                        backgroundColor: '#C4E7FF',
                                                                        borderRadius: 8,
                                                                    }}>
                                                                    <EditIcon sx={{
                                                                        color: '#00658B'
                                                                    }} />
                                                                </Button>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                                : ''
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </Box>
                </Grid>
                <Grid item sm={12} md={6} lg={6} paddingLeft={'10px'}>
                    <Box
                        display={'inline-grid'}
                        height={'500px'}
                        overflow={'auto'}
                        width={'100%'}
                        paddingBottom={2}
                    >
                        <Card>
                            <Grid container direction={'row'} justifyContent={'space-between'} paddingX={2} marginTop={2}>
                                <Grid item>
                                    <Typography fontWeight={600} variant='h5'>User Terbaru</Typography>
                                </Grid>
                                <Grid item>
                                    <Button sx={{ color: '#00658B' }} variant='text'>Lihat Semua</Button>
                                </Grid>
                            </Grid>
                            <TableContainer sx={{ paddingX: 1 }}>
                                <Table>
                                    <TableHead sx={{ background: '#C4E7FF' }}>
                                        <TableRow>
                                            <TableCell>
                                                Nama User
                                            </TableCell>
                                            <TableCell>
                                                E-mail
                                            </TableCell>
                                            <TableCell>
                                                PIN
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pendaftar_baru.length > 0
                                            ? pendaftar_baru
                                                .map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                            <TableCell>
                                                                {row.nama}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.email}
                                                            </TableCell>
                                                            <TableCell>
                                                                {ShowPin(row.pin, showref, setShowRef)}
                                                            </TableCell>
                                                            <TableCell
                                                                align='center'
                                                                key={row.id}
                                                            >
                                                                <Grid item>
                                                                    <Button
                                                                        LinkComponent={Link}
                                                                        to={'/admin/user/' + row['id'].toString()}
                                                                        variant='text'
                                                                        size='small'
                                                                        sx={{
                                                                            backgroundColor: '#C4E7FF',
                                                                            borderRadius: 8,
                                                                        }}>
                                                                        <EditIcon sx={{
                                                                            color: '#00658B'
                                                                        }} />
                                                                    </Button>
                                                                </Grid>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            : <></>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Dashboard;
