// import * as React from 'react';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { createTheme, useTheme } from '@mui/material/styles';
import { Button, Card, Divider, Grid, Typography } from '@mui/material';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { tokens } from '../../theme';
import Hero from '../../assets/image/tracking.png'
import { headerData } from '../../data/headerCostum';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';

export default function Tracking() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataTracking, setDataTracking] = useState(null);
    const [dataPermohonan, setDataPermohonan] = useState(null);
    const user = JSON.parse(localStorage.getItem('user'));

    const getDataTracking = () => {
        fetch(process.env.REACT_APP_API_URL + "api/traking_permohonan/" + user.id, {
            method: 'get',
            headers: headerData,
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.id_user) {
                    setDataTracking(res);
                    // console.log(res)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (!dataTracking) {
            getDataTracking();
        }
    }, [dataTracking, getDataTracking])

    return (
        <Grid
            container
            direction={"column"}
            alignSelf={'center'}
            alignContent={'center'}
            height={'100vh'}
            maxWidth={'xs'}
        >
            <Box width={'100vw'} height={'100vh'} zIndex={-1} position={'fixed'} top={0} sx={{
                bgcolor: '#D1E5F4',
            }}>
            </Box>
            <Grid container item direction={'column'}
                sx={{
                    padding: 2,
                    marginTop: 6,
                }}>
                <Grid item container direction={'row'} justifyContent={'center'}>
                    <Grid container item justifyContent={'center'}>
                        <Grid item>
                            <img src={Hero} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item direction={'column'} alignContent={'center'}>
                    <Grid item>
                        <Typography variant='h2' color={"#00658B"}>
                            {'Tracking Permohonan'}
                        </Typography>
                        <Typography>
                            {'Lihat progress permohonan anda'}
                        </Typography>
                    </Grid>
                    <Typography variant='body1' marginBottom={0.5}>
                        Tracking Permohonan
                    </Typography>
                    <Divider sx={{
                        borderColor: 'CaptionText',
                        marginBottom: 2
                    }} />
                    <Box minWidth={100}>
                        {
                            dataTracking
                                ? <Timeline
                                    sx={{
                                        [`& .${timelineItemClasses.root}:before`]: {
                                            flex: 0,
                                            padding: 0,
                                        },
                                    }}>
                                    {
                                        dataTracking.permohonan_layanan
                                            ? <TimelineItem key={dataTracking.id}>
                                                <TimelineSeparator>
                                                    <TimelineDot color={dataTracking.permohonan_layanan ? 'success' : 'grey'} />
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent sx={{ py: '3px', px: 1 }}>
                                                    <Typography color={!dataTracking.permohonan_layanan ? 'GrayText' : 'InfoText'} variant="body1" fontWeight={600} component="span">
                                                        {"Permohonan Layanan"}
                                                    </Typography>
                                                    <Typography variant="body1">{dataTracking.permohonan_layanan ? dataTracking.permohonan_layanan : ""}</Typography>
                                                </TimelineContent>
                                            </TimelineItem>
                                            : <></>
                                    }
                                    {
                                        <TimelineItem key={dataTracking.id + 1}>
                                            <TimelineSeparator>
                                                <TimelineDot color={dataTracking.varifikasi ? (dataTracking.berkas_lengkap ? "success" : "warning") : 'grey'} />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '3px', px: 1 }}>
                                                <Typography color={!dataTracking.varifikasi ? 'GrayText' : 'InfoText'} variant="body1" fontWeight={600} component="span">
                                                    {"Verifikasi"}
                                                </Typography>
                                                <Typography variant="body1">{dataTracking.varifikasi ? dataTracking.varifikasi : ""}</Typography>
                                                <Button
                                                    fullWidth
                                                    variant='contained'
                                                    sx={{
                                                        textTransform: 'none',
                                                        bgcolor: colors.greenAccent[400],
                                                        ":hover": {
                                                            bgcolor: colors.blueAccent[500],
                                                        },
                                                        alignItems: 'flex-start',
                                                        borderRadius: 2
                                                    }}
                                                    LinkComponent={Link}
                                                    to='/user/pilihpermohonan'
                                                >
                                                    Lihat Permohonan
                                                </Button>
                                            </TimelineContent>
                                        </TimelineItem>
                                    }
                                    {
                                        <TimelineItem key={dataTracking.id + 2}>
                                            <TimelineSeparator>
                                                <TimelineDot color={dataTracking.berkas_lengkap ? (dataTracking.proses_unit_kerja ? "success" : "warning") : 'grey'} />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '3px', px: 1 }}>
                                                <Typography color={!dataTracking.berkas_lengkap ? 'GrayText' : 'InfoText'} variant="body1" fontWeight={600} component="span">
                                                    {"Berkas Lengkap"}
                                                </Typography>
                                                <Typography variant="body1">{dataTracking.berkas_lengkap ? dataTracking.berkas_lengkap : "Menuggu perubahan status"}</Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    }
                                    {
                                        <TimelineItem key={dataTracking.id + 3}>
                                            <TimelineSeparator>
                                                <TimelineDot color={dataTracking.proses_unit_kerja ? 'success' : 'grey'} />
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: '3px', px: 1 }}>
                                                <Typography color={!dataTracking.proses_unit_kerja ? 'GrayText' : 'InfoText'} variant="body1" fontWeight={600} component="span">
                                                    {"Proses Unit Kerja"}
                                                </Typography>
                                                <Typography variant="body1">{dataTracking.proses_unit_kerja ? dataTracking.proses_unit_kerja : "Menunggu Perubahan Status"}</Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    }
                                </Timeline>
                                : <></>
                        }
                    </Box>

                    <Box height={'50px'} />
                </Grid>
            </Grid>
        </Grid>
    );
}