import React, { Suspense } from 'react';
import { Alert, CssBaseline, Grid, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import {
  RouterProvider, createHashRouter,
  createRoutesFromElements, defer,
  Route, useOutlet,
  useLoaderData, Await, Navigate, useLocation
} from "react-router-dom";

import Dashboard from "./scenes/admin/dashboard";
import Menu from './scenes/admin/global/Menu';
import Login from './scenes/login';
import { AuthProvider, useAuth } from './auth/auth_provider';
import DaftarUser from './scenes/admin/akun';
import SplashScreen from './scenes/spashscreen';
import Pendaftaran from './scenes/pendaftaran/index';
import Beranda from './scenes/pendaftaran/beranda';
import ListPermohonan from './scenes/permohonan/list_permohonan';
import BottomNavbar from './scenes/user/global/navbar';
import LKO_data_permohonan from './scenes/permohonan/lko_data_permohonan';
import LKO_kepengurusan from './scenes/permohonan/lko_kepengurusan';
import DaftarPerdaftar from './scenes/admin/daftaruser';
import DetailUser from './scenes/admin/daftaruser/detail';
import SideMenu from './scenes/admin/global/Menu';
import DaftarPermohonan from './scenes/admin/permohonan_lko';
import LKO_administrasi from './scenes/permohonan/lko_administrasi';
import DetailPermohonanLKO from './scenes/admin/permohonan_lko/detail';
import Tracking from './scenes/pendaftaran/tracking';
import Konsultasi from './scenes/pendaftaran/konsultasi';
import SKT_data_permohonan from './scenes/permohonan/skt_data_permohonan';



const App = () => {
  const [theme, colorMode] = useMode()
  // const { user } = useAuth();
  const router =
    createHashRouter(
      createRoutesFromElements(
        <Route path='/' element={<AuthLayout />}
          loader={() => defer({ userPromise: getUserData() })}
        >
          <Route path="login" element={<Login />} />
          <Route index element={<Pendaftaran />} />
          <Route path="user" element={<ProtectedUserLayout />}>
            <Route index element={<Navigate to='/user/beranda' />} />
            <Route path="beranda" element={<Beranda />} />
            <Route path="tracking" element={<Tracking />} />
            <Route path="konsultasi" element={<Konsultasi />} />
            <Route path="pilihpermohonan" element={<ListPermohonan />} />
            <Route path="sktdatapermohonan" element={<SKT_data_permohonan />} />

            <Route path="lkodatapermohonan" element={<LKO_data_permohonan />} />
            <Route path="lkokepengurusan" element={<LKO_kepengurusan />} />
            <Route path="lkoadministrasi" element={<LKO_administrasi />} />
          </Route>
          <Route path="admin" element={<ProtectedLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="akun" element={<DaftarUser />} />
            <Route path="user" element={<DaftarPerdaftar />} />
            <Route path="user/:userId" element={<DetailUser />} />
            <Route path="permohonan/lko" element={<DaftarPermohonan />} />
            <Route path="permohonan/lko/:idLKO" element={<DetailPermohonanLKO />} />
          </Route>
        </Route>
      )
    );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </ColorModeContext.Provider>

  );
}

const getUserData = () =>
  new Promise((resolve, reject) =>
    setTimeout(() => {
      const user = window.localStorage.getItem("user");
      resolve(user);
      reject("Error");
    }, 3000)
  );

const titles = {
  'main': 'Karebosi Apps',
  '/admin/dashboard': 'Dashboard',
}

const ProtectedLayout = () => {
  const { admin } = useAuth();
  if (!admin) {
    return <Navigate to="/login" />;
  } else {
    return (
      <Grid sx={{
        height: '100vh',
        backgroundColor: '#D1E5F4'
      }}>
        <SideMenu />
      </Grid>
    );
  }
};


const ProtectedUserLayout = () => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/" />;
  } else {
    return (
      <div>
        <BottomNavbar />
      </div>
    );
  }

};



const AuthLayout = () => {
  const oulet = useOutlet();
  const { userPromise } = useLoaderData();

  return (
    <Suspense fallback={<SplashScreen />}>
      <Await
        resolve={userPromise}
        errorElement={<Alert severity="error">Something went wrong!</Alert>}
        children={
          (user) => (
            <AuthProvider userData={user}>
              {oulet}
            </AuthProvider>
          )
        }
      />
    </Suspense>
  );
}


export default App;
