import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Typography } from '@mui/material';

const BasicTimeline = ({ data }) => {
    return (
        <Timeline
            sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                },
            }}
        >
            {
                data
                    ? data.map((item) => {
                        return (<TimelineItem>
                            <TimelineSeparator>
                                <TimelineDot color={item.status ? 'success' : 'grey'} />
                                {!item.akhir ? <TimelineConnector /> : ''}
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '5px', px: 2 }}>
                                <Typography color={!item.status ? 'GrayText' : 'InfoText'} variant="h5" fontWeight={600} component="span">
                                    {item.name}
                                </Typography>
                                <Typography variant="body1">{item.desc}</Typography>
                            </TimelineContent>
                        </TimelineItem>)
                    })
                    : '...'
            }
        </Timeline>
    );
}

export default BasicTimeline;