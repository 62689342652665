import React from 'react';
import { useState, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Outlet, useLocation, Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Avatar, Collapse, Menu, MenuItem, Tooltip, useMediaQuery } from '@mui/material';
import { tokens, ColorModeContext } from '../../../theme';
import Logo from '../../../assets/image/logo.png';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import { useAuth } from '../../../auth/auth_provider';
import { DescriptionRounded, ExpandLess, ExpandMore, SubdirectoryArrowRightRounded } from '@mui/icons-material';


const drawerWidth = 250;
const iconMenuSize = 20;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));



const DrawerMini = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const Item = ({ title, to, icon, selected, setSelected, isOpen }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <ListItem key={title.replace(/\W+/g, '_').toLowerCase()} disablePadding sx={{ display: 'block', marginY: 1 }}>
            <ListItemButton
                sx={{
                    minHeight: 35,
                    justifyContent: isOpen ? 'flex-start' : 'center',
                    alignContent: isOpen ? 'center' : 'center',
                    px: 2.5,
                    color: colors.grey,
                    backgroundColor: (selected === to) ? colors.blueAccent[400] : colors.primary[400],
                    borderRadius: 10,
                    marginX: isOpen ? 2 : 0
                }}
                component={Link}
                to={to}
                onClick={() => setSelected(to)}
            >
                <Grid
                    container
                    direction={'row'}
                    gridTemplateColumns="repeat(12, 1fr)"
                    gridAutoRows="250px"
                    gap="20px"
                >
                    <Grid item>
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                justifyContent: 'center',
                                alignContent: 'center',
                                color: (selected === to) ? colors.primary[400] : colors.grey[400],
                            }}
                        >
                            <Box >
                                {icon}
                            </Box>
                        </ListItemIcon>
                    </Grid>
                    {
                        isOpen
                            ? <Grid item>
                                <ListItemText primary={title}
                                    sx={{
                                        opacity: 1,
                                        color: (selected === to) ? colors.primary[400] : colors.grey[400],
                                        textAlign: 'center',
                                        display: 'visible',
                                    }}
                                    primaryTypographyProps={{ fontSize: '12px' }}
                                />
                            </Grid>
                            : <></>
                    }
                </Grid>
            </ListItemButton>
        </ListItem>
    );
};

const SideMenu = (props) => {
    let location = useLocation();
    const { user, logout } = useAuth();
    // Give us meaningful document titles for popping back/forward more than 1 entry
    const [selected, setSelected] = useState("Dashboard");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const [openListMenu, setOpenListMenu] = useState(true)

    const handleListMenu = () => {
        setOpenListMenu(!openListMenu);
    };



    React.useEffect(() => {
        document.title = location.pathname.split('/')[2];
        setSelected(document.title)
    }, [location, setSelected]);

    const { window } = props;

    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };



    const contain = window !== undefined ? () => window().document.body : undefined;

    const drawer = (
        <Grid sx={{ height: '100vh', backgroundColor: '#D1E5F4' }}>
            <DrawerHeader>
                <Grid sx={{ flexGrow: 1, margin: 2 }} component={"div"} container justifyContent='center'>
                    {open ? <img src={Logo} alt="kesbangpol" width={'180px'} /> : <></>}
                </Grid>
            </DrawerHeader>
            <Divider />
            <List>
                <Item
                    title="Dashboard"
                    to="dashboard"
                    icon={<GridViewRoundedIcon sx={{ fontSize: iconMenuSize }} />}
                    selected={selected}
                    setSelected={setSelected}
                    isOpen={open}
                />
                <ListItemButton onClick={handleListMenu}
                    sx={{
                        minHeight: 35,
                        justifyContent: open ? 'flex-start' : 'center',
                        alignContent: open ? 'center' : 'center',
                        px: 2.5,
                        color: colors.grey,
                        backgroundColor: (openListMenu) ? colors.blueAccent[400] : colors.primary[400],
                        borderRadius: 10,
                        marginX: open ? 2 : 0
                    }}
                >
                    <Grid
                        container
                        direction={'row'}
                        gridTemplateColumns="repeat(12, 1fr)"
                        // gridAutoRows="50px"
                        gap="20px"
                    >
                        <Grid item>
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    color: (openListMenu) ? colors.primary[400] : colors.grey[400],
                                }}
                            >
                                <Box >
                                    <DescriptionRounded sx={{ fontSize: iconMenuSize }} />
                                </Box>
                            </ListItemIcon>
                        </Grid>
                        <Grid item>
                            <ListItemText primary={'Permohonan'}
                                sx={{
                                    opacity: open ? 1 : 0,
                                    color: (openListMenu) ? colors.primary[400] : colors.grey[400],
                                    textAlign: 'center',
                                    display: open ? 'visible' : 'none',
                                }}
                                primaryTypographyProps={{ fontSize: '12px' }}
                            />
                        </Grid>
                        {open ? <Grid item>
                            {openListMenu ? <ExpandLess /> : <ExpandMore />}
                        </Grid> : <></>}
                    </Grid>
                </ListItemButton>
                <Collapse in={openListMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{marginLeft: 2}}>
                        <Item
                            title="SKT"
                            to="permohonan/skt"
                            icon={<SubdirectoryArrowRightRounded sx={{ fontSize: iconMenuSize }} />}
                            selected={selected}
                            setSelected={setSelected}
                            isOpen={open}
                        />
                    </List>
                    <List component="div" disablePadding sx={{marginLeft: 2}}>
                        <Item
                            title="Laporan Keberadaan"
                            to="permohonan/lko"
                            icon={<SubdirectoryArrowRightRounded sx={{ fontSize: iconMenuSize }} />}
                            selected={selected}
                            setSelected={setSelected}
                            isOpen={open}
                        />
                    </List>
                </Collapse>
                <Item
                    title="Daftar User"
                    to="user"
                    icon={<PeopleAltRoundedIcon sx={{ fontSize: iconMenuSize }} />}
                    selected={selected}
                    setSelected={setSelected}
                    isOpen={open}
                />
                <Item
                    title="Daftar Admin"
                    to="akun"
                    icon={<PeopleAltRoundedIcon sx={{ fontSize: iconMenuSize }} />}
                    selected={selected}
                    setSelected={setSelected}
                    isOpen={open}
                />
            </List>
            <Divider />
        </Grid>
    );

    const matches = useMediaQuery(theme.breakpoints.up('sm'));


    return (
        <Box sx={{ display: 'flex', backgroundColor: '#D1E5F4' }}>
            <CssBaseline />
            <AppBar elevation={0} position="fixed" open={open} >
                <Toolbar style={{
                    background: `#D1E5F4`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',
                }}>
                    <Grid container justifyContent={"space-between"} alignItems={'center'} width={"100%"}>
                        <Grid item container alignItems={'center'}>
                            <Grid item>
                                <IconButton
                                    color="#001E2D"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    sx={{
                                        marginRight: 5,
                                        ...(open && { display: 'none' }),
                                    }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <IconButton
                                    color="#001E2D"
                                    aria-label="open drawer"
                                    onClick={handleDrawerClose}
                                    edge="start"
                                    sx={{
                                        marginRight: 3,
                                        ...(!open && { display: 'none' }),
                                    }}>
                                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item container alignItems={'center'}>
                            <Grid item container>
                                <Tooltip title="Account settings">
                                    <IconButton
                                        onClick={handleClickMenu}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={openMenu ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openMenu ? 'true' : undefined}
                                    >
                                        <Avatar sx={{ width: 30, height: 30 }}>
                                            {/* <img src={Logo} alt="avatar" width={'100%'} /> */}
                                        </Avatar>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={openMenu}
                                    onClose={handleCloseMenu}
                                    onClick={handleCloseMenu}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&::before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem onClick={colorMode.toggleColorMode}>
                                        <ListItemIcon>
                                            {theme.palette.mode === "dark" ? (
                                                <DarkModeOutlinedIcon />
                                            ) : (
                                                <LightModeOutlinedIcon />
                                            )}
                                        </ListItemIcon>
                                        Ganti Warna
                                    </MenuItem>
                                    <MenuItem onClick={logout}>
                                        <ListItemIcon>
                                            {/* <IconButton sx={{
                                                backgroundColor: colors.redAccent[500],
                                                ":hover": {
                                                    backgroundColor: colors.redAccent[700]
                                                }
                                            }} onClick={logout}> */}
                                            <LogoutRoundedIcon sx={{
                                                color: colors.grey[100]
                                            }} />
                                            {/* </IconButton> */}
                                        </ListItemIcon>
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer
                container={contain}
                variant="temporary"
                open={!matches ? open : false}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.                  
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    overflow: "auto",
                }}
            >
                {/* <Box sx={{
                    height: '100vh'
                }}> */}
                {drawer}
                {/* </Box> */}
            </Drawer>
            <DrawerMini
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                }}
                open={open}>
                {drawer}
            </DrawerMini>
            <Box component="main" sx={{ flexGrow: 1, p: 3, width: "100%", maxHeight: '100vh', overflow: 'auto' }} >
                <DrawerHeader />
                <Grid>
                    <Outlet />
                </Grid>
            </Box>
        </Box>
    );
}

export default SideMenu;