// import * as React from 'react';
import React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Card, Container, Divider, Grid, useTheme } from '@mui/material';
import LoginUser from './login';
import Daftar from './daftar';
import Hero from '../../assets/image/hero.png';
import Head from '../../assets/image/head.png';
import { tokens } from '../../theme';

export default function Pendaftaran() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isDaftar, setIsDaftar] = useState(false);
    return (
        <Grid
            container
            direction={"column"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            height={"100vh"}
            paddingX={2}
        >
            <Grid item md={4} sm={8} xs={12} alignItems={'center'} sx={{paddingTop: 2}}>
                <Grid container item justifyContent={'center'}>
                    <Grid item>
                        <img src={Head} width={'100%'} />
                    </Grid>
                </Grid>
                <Grid container item justifyContent={'center'}>
                    <Grid item>
                        <img src={Hero} height={ isDaftar ? '200px' : '100%'} />
                    </Grid>
                </Grid>
                <Card>
                    <Grid container
                        sx={{
                            bgcolor: colors.primary[900],
                            borderRadius: 2,
                            paddingX: 2
                        }}
                    >
                        <Grid item justifyContent='center' padding={0.5} alignItems={'center'} xs={6}>
                            <Button fullWidth
                                variant={!isDaftar ? 'contained' : 'text'}
                                size='medium'
                                onClick={() => setIsDaftar(!isDaftar)}
                                sx={{
                                    textTransform: 'none',
                                    bgcolor: !isDaftar ? colors.blueAccent[500] : '',
                                    color: isDaftar ? colors.blueAccent[500] : '',
                                    fontWeight: 700,
                                    ":hover": {
                                        bgcolor: !isDaftar ? colors.blueAccent[400] : '',
                                    }
                                }}
                            >Login
                            </Button>
                        </Grid>
                        <Grid item justifyContent='center' padding={0.5} alignItems={'center'} xs={6}>
                            <Button fullWidth
                                variant={isDaftar ? 'contained' : 'text'}
                                size='medium'
                                onClick={() => setIsDaftar(!isDaftar)}
                                sx={{
                                    textTransform: 'none',
                                    bgcolor: isDaftar ? colors.blueAccent[500] : '',
                                    color: !isDaftar ? colors.blueAccent[500] : '',
                                    fontWeight: 700,
                                    ":hover": {
                                        bgcolor: isDaftar ? colors.blueAccent[400] : '',
                                    }
                                }}
                            >Daftar
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container >
                        {!isDaftar ? <LoginUser /> : <Daftar />}
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
}