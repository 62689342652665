// import * as React from 'react';
import React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { Card, Divider, Grid, InputAdornment, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../../auth/auth_provider';
import { headerData } from '../../data/headerCostum';
import { tokens } from '../../theme';
import { AccountCircle } from '@mui/icons-material';
import ShowAlert from '../admin/global/alert';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="#">
                Arima Digital Indonesia
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();
const styles = {
    paperContainer: {

    }
};


export default function LoginUser() {
    const { loginUser } = useAuth();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [alertOption, setAlertOption] = useState({
        title: '',
        desc: '',
        type: 'info'
    });
    const [openAlert, setOpenAlert] = useState(false);
    const hadleAlert = () => {
        setOpenAlert(false);
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        fetch(process.env.REACT_APP_API_URL + "api/pendaftar/login", {
            method: 'POST',
            headers: headerData,
            body: JSON.stringify({
                pin: data.get('pin')
            })
        })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.token) {
                    loginUser(res.user);
                } else {
                    setAlertOption(
                        {
                            title: 'Login Gagal',
                            desc: 'Periksa Pin Anda',
                            type: 'error'
                        }
                    );
                }
                setOpenAlert(true);
                setTimeout(hadleAlert, 4000);
            })
            .catch(err => {
                console.log(err);
            });

    };


    return (
        <Grid item sx={{
            alignContent: 'center',
            alignItems: 'center',
        }}>
            <ThemeProvider theme={defaultTheme}>
                <Container sx={styles.paperContainer} component="main" maxWidth="xs">
                    <CssBaseline />
                    <ShowAlert
                        title={alertOption.title}
                        desc={alertOption.desc}
                        type={alertOption.type}
                        openAlert={openAlert}
                        onAlertClose={hadleAlert}
                    />
                    <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                        <Box component="form" width={'100%'} onSubmit={handleSubmit}>
                            <TextField
                                margin="dense"
                                required
                                fullWidth
                                id="pin"
                                label=""
                                name="pin"
                                autoComplete="pin"
                                autoFocus
                                size='small'
                                placeholder='Masukkan PIN'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 1,
                                    mb: 2,
                                    bgcolor: colors.blueAccent[500],
                                    textTransform: 'none'
                                }}
                                size='small'
                            >
                                Login
                            </Button>
                        </Box>
                    </Box>
                    {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
                </Container>
            </ThemeProvider>
        </Grid>
    );
}