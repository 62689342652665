import { CircularProgress, Grid, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Logo from '../assets/image/logo.png';
import LogoKes from '../assets/image/logoKes.png';
import LogoKot from '../assets/image/logoPemkot.png';
import { tokens } from "../theme";

const SplashScreen = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box sx={{
            background: '#D1E5F4',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh',
            width: '100vw',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }}>
            <Grid item container xs={12} justifyContent="center" sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}>
                <Grid xs={12} md={9} item>
                    <Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingX: 3,
                            marginY: 2
                        }}>
                            <img src={Logo} alt="karebosi" width={'250px'} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                paddingX: 3,
                                marginY: 4
                            }}
                        >
                            <CircularProgress sx={{
                                color: colors.blueAccent[500],
                            }}
                            size={50}
                            thickness={7}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyItems: 'center',
                            justifyContent: 'center',
                            paddingX: 3,
                            marginY: 1
                        }}>
                            <img src={LogoKes} alt="karebosi" width={'60px'} />
                            <img src={LogoKot} alt="karebosi" width={'60px'} />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingX: 3,
                            marginY: 1,
                            color: colors.blueAccent[500],
                        }}>
                            <Typography fontFamily={''}>
                                BADAN KESATUAN BANGSA & POLITIK
                            </Typography>
                            <Typography>
                                KOTA MAKASSAR
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default SplashScreen;